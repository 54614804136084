let id: number;

/**
 * @const useDebounce
 */
const useDebounce = (waitTime: number) => {

    return (callback: () => void) => {
        window.clearTimeout(id);

        id = window.setTimeout(
            callback,
            waitTime
        );
    }
};

export default useDebounce;
