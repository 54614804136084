import React, { PropsWithChildren } from "react";
import i18n from "./config";
import {I18nextProvider} from "react-i18next";

const LanguageProvider = ({ children }: PropsWithChildren) => {

    i18n.changeLanguage('en_US');

    return (
        <I18nextProvider i18n={ i18n }>
            { children }
        </I18nextProvider>
    );
};

LanguageProvider.displayName = "LanguageProvider";

export default LanguageProvider;