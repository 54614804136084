import { Components, Theme } from "@mui/material";

const MuiTypography: Components<Theme>["MuiTypography"] =  {
    styleOverrides: {
        root: {
            lineHeight: 1.2,
        },
        h1: ({ theme }) => ({
            color: theme.palette.primary.main,
            fontSize: 20,
            fontWeight: "700",
            [theme.breakpoints.up("md")]: {
                fontSize: 30,
            },
        }),
    },
};

export default MuiTypography;