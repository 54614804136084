import React, {FC, useState} from 'react';
import {Box, Button, Grid, Tab, Tabs, Typography} from "@mui/material";
import {TabPanel} from "../../tabs/TabPanel";
import {Form, Formik, FormikHelpers} from "formik";
import {useGetSettingsQuery, useSaveSettingsMutation} from "../../../redux/api/settings";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {Helmet} from "react-helmet-async";
import {GeneralForm} from "./settings/GeneralForm";
import {EmailsForm} from "./settings/EmailsForm";
import {useTranslation} from "react-i18next";
import {RetailServiceForm} from "./settings/RetailServiceForm";

const Settings: FC<{}> = () => {
    const [t] = useTranslation();
    const {data: settings} = useGetSettingsQuery();
    const [currentTab, setCurrentTab] = useState(0);
    const [saveSettings] = useSaveSettingsMutation();

    const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await saveSettings(values).unwrap();
            NotificationHelper.showSuccess(t('settings:saved'));
        } catch (e) {
            ApiErrorHelper.processErrors(e, formikHelpers);
        }
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Helmet>
                <title>{t('helmet:settings')}</title>
            </Helmet>
            <Grid container>
                <Grid item xs={12} mb={3}>
                    <Typography component="h1" variant="h1">
                        {t('pageTitle:settingsManagement')}
                    </Typography>
                </Grid>
                <Box sx={{width: '100%'}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={settings || {}}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'block', width: '100%' }}>
                                    <Tabs value={currentTab} onChange={handleTabChange}>
                                        <Tab label={t('settings:general')} />
                                        <Tab label={t('settings:retailService')} />
                                        <Tab label={t('settings:emails')} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={currentTab} index={0}>
                                    <GeneralForm />
                                </TabPanel>
                                <TabPanel value={currentTab} index={1}>
                                    <RetailServiceForm />
                                </TabPanel>
                                <TabPanel value={currentTab} index={2}>
                                    <EmailsForm formik={formik} />
                                </TabPanel>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                    <Button autoFocus color="primary" type="submit" variant="contained">
                                        {t('button:save')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Grid>
        </>
    );
}

export default Settings;
