import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    retry
} from '@reduxjs/toolkit/query/react'
import {type RootState} from "../store";

const baseQuery = retry(fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        // @ts-ignore
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }

        return headers;
    }
}), {
    retryCondition: (error, args, { attempt}) => {
        if (error.status === 422 || error.status === 403 || error.status === 401 || error.status === 404 || error.status === 400) {
            return false;
        }

        return attempt < 3;
    }
});

const baseQueryWith401Handling: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // @ts-ignore
    if (result.error && result.error.status === 401) {
        api.dispatch({ type: 'auth/logout' });
    }

    return result
};

// Initialize an empty api service that we'll inject endpoints into later as needed
const baseApi = createApi({
    baseQuery: baseQueryWith401Handling,
    tagTypes: [
        'avatar',
        'labels',
        'skuList',
        'labelList',
         'source',
        'sourcesList',
        'salesOrder',
    ],
    endpoints: () => ({}),
});

export default baseApi;
