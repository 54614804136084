import {useAppSelector} from "./useAppSelector";
import {userHasRole} from "../helpers/security";
import useJwt from "./useJwt";

/**
 * Hook to check if the current user has a specific role.
 */
const useSecurity = () => {
    const jwt = useJwt();
    let currentRoles = useAppSelector(state => state.auth.currentRoles);

    if (!currentRoles || currentRoles.length === 0) {
        currentRoles = jwt?.roles; // Fallback to the JWT roles until the user is loaded
    }

    /**
     * Check if the current user has a specific role.
     * @param role
     */
    const hasRole = (role: string) => {
        if (!currentRoles || currentRoles.length === 0) {
            return false;
        }

        return userHasRole(role, currentRoles);
    }

    return hasRole;
}

export default useSecurity;
