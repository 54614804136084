import React, {FC} from "react";
import {TextField as MuiTextField, TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";

export type TextFieldProps = MuiTextFieldProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    valueParser?: (value: string) => any,
    onChange?: (event: React.ChangeEvent<any>) => void,
};

export const TextField: FC<TextFieldProps> = ({formik, name, label, onChange, valueParser, ...restProps}) => {
    const value = ArrayHelper.get(formik.values, name);

    const defaultOnChange = (event: React.ChangeEvent<any>) => {
        if (valueParser) {
            formik.setFieldValue(event.target.name, valueParser(event.target.value));
        } else {
            formik.handleChange(event);
        }

        onChange && onChange(event);
    }

    return (
        <MuiTextField
            id={name}
            name={name}
            label={label || name}
            value={value}
            onChange={defaultOnChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name] as string}
            {...restProps}
        />
    );
}
