import React, {FC, PropsWithChildren} from 'react';
import {Navigate} from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import useSecurity from "../../hooks/useSecurity";

type SecureRouteProps = PropsWithChildren & {
    role?: string;
    hideForRole?: string;
}

export const SecurePage: FC<SecureRouteProps> = ({hideForRole, role, children}) => {
    const jwt = useJwt();
    const hasRole = useSecurity();

    let redirect = true;
    if(jwt !== null) {
        if (jwt.exp && Date.now() < jwt.exp * 1000) redirect = false;

        if(hideForRole && hasRole(hideForRole)) redirect = true;
        if(role) redirect = !hasRole(role);
    } else {
        return <Navigate to="/login" />;
    }

    return redirect ? <Navigate to="/error/not-allowed" /> : children as JSX.Element;
}
