import React, { FC, useEffect, useState } from "react";
import { Grid, TextField, Box, Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import { Portion, SourceData } from "../../types/api/responses/source/SourceResponse";

export type LabelPreviewProps = {
    data: SourceData['nutrition_facts'],
    onDataChange: (updatedData: SourceData['nutrition_facts']) => void;
};

const nutritionOrder = [
    'Energy',
    'Fats',
    'Fat',
    'of which saturated',
    'of which saturates',
    'of which saturated fatty acids',
    'Carbohydrates',
    'Carbohydrate',
    'of which sugars',
    'Fibres',
    'Fibre',
    'Proteins',
    'Protein',
    'Salt',
];

function isPortionObject(value: any): value is { [x: string]: Portion } {
    return typeof value === 'object' && value !== null && !('100g' in value) && !('serving' in value);
}

const LabelPreview: FC<LabelPreviewProps> = ({ data: initialData, onDataChange }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(
        Object.entries(initialData).map(([key, value]) => ({
            key,
            value: {
                ...value,
            },
            subOrder: isPortionObject(value) ? Object.keys(value) : [], // Keep track of the order of subkeys
        })).sort((a, b) => {
            const indexA = nutritionOrder.indexOf(a.key);
            const indexB = nutritionOrder.indexOf(b.key);
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        })
    );

    useEffect(() => {
        const updatedData = data.reduce((acc, item) => {
            if (isPortionObject(item.value)) {
                acc[item.key] = item.subOrder.reduce((subAcc, subKey) => {
                    subAcc[subKey] = (item.value as { [key: string]: Portion })[subKey];
                    return subAcc;
                }, {} as { [key: string]: Portion });
            } else {
                acc[item.key] = { '100g': item.value['100g'], serving: item.value.serving, daily: item.value.daily };
            }
            return acc;
        }, {} as SourceData['nutrition_facts']);

        onDataChange(updatedData);
    }, [data, onDataChange]);

    const addNewEntry = () => {
        setData(prevData => [...prevData, { key: '', value: { '100g': '', serving: '', daily: '' }, subOrder: [] }]);
    };

    const handleKeyChange = (index: number, newKey: string, subKey?: string) => {
        setData(prevData => {
            const newData = [...prevData];

            if (subKey && isPortionObject(newData[index].value)) {
                const portionObject = newData[index].value as { [key: string]: Portion };
                const subOrder = newData[index].subOrder;

                const subIndex = subOrder.indexOf(subKey);
                if (subIndex > -1) {
                    subOrder[subIndex] = newKey;
                }

                portionObject[newKey] = portionObject[subKey];
                delete portionObject[subKey];
            } else {
                newData[index].key = newKey;
            }

            return newData;
        });
    };

    const handleValueChange = (
        index: number,
        field: '100g' | 'serving' | 'daily',
        value: string,
        subKey?: string
    ) => {
        setData(prevData => {
            const newData = prevData.map((item, i) => {
                if (i !== index) return item;

                if (subKey && isPortionObject(item.value)) {
                    const portionObject = { ...item.value } as { [key: string]: Portion };
                    const updatedPortion = { ...portionObject[subKey], [field]: value };
                    portionObject[subKey] = updatedPortion;

                    return { ...item, value: portionObject };
                } else if (!subKey && !isPortionObject(item.value)) {
                    const updatedValue = { ...item.value as { '100g': string; serving: string; daily: string }, [field]: value };
                    return { ...item, value: updatedValue };
                } else {
                    return item;
                }
            });

            return newData;
        });
    };

    const removeEntry = (index: number, subKey?: string) => {
        setData(prevData => {
            const newData = [...prevData];

            if (subKey && isPortionObject(newData[index].value)) {
                const subOrder = newData[index].subOrder;
                const subIndex = subOrder.indexOf(subKey);
                if (subIndex > -1) {
                    subOrder.splice(subIndex, 1);
                }
                delete (newData[index].value as { [key: string]: Portion })[subKey];

                if (Object.keys(newData[index].value).length === 0) {
                    newData.splice(index, 1);
                }
            } else {
                newData.splice(index, 1);
            }

            return newData;
        });
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2} alignItems="center" sx={{ paddingBottom: 2, background: '#F8F6F2', width: "calc(100% + 32px)" }}>
                <Grid item xs={4}>
                    <Typography sx={{ fontWeight: "bold" }}>{t('labels:nutritionValue')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: "bold" }}>{t('labels:per100gram')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: "bold" }}>{t('labels:perServing')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ fontWeight: "bold" }}>{t('labels:daily')}</Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
            </Grid>
            {data.map((item, index) => (
                isPortionObject(item.value) ? (
                    item.subOrder.map((subKey, subIndex) => (
                        <React.Fragment key={`${index}-${subIndex}`}>
                            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: "10px", paddingTop: "10px" }}>
                                {subIndex === 0 ?
                                    <>
                                        <Grid item xs={4}>
                                            <TextField
                                                value={item.key}
                                                onChange={(e) => handleKeyChange(index, e.target.value)}
                                                fullWidth
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item xs={8}/>
                                        <Divider />
                                    </>
                                    :
                                    <>
                                        <Grid item xs={4}>
                                            <TextField
                                                value={subKey}
                                                onChange={(e) => handleKeyChange(index, e.target.value, subKey)}
                                                sx={{marginLeft: "40px", width: "calc(100% - 40px)"}}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                value={(item.value as { [key: string]: Portion })[subKey]['100g']}
                                                onChange={(e) => handleValueChange(index, '100g', e.target.value, subKey)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                value={(item.value as { [key: string]: Portion })[subKey].serving}
                                                onChange={(e) => handleValueChange(index, 'serving', e.target.value, subKey)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                value={(item.value as { [key: string]: Portion })[subKey].serving}
                                                onChange={(e) => handleValueChange(index, 'daily', e.target.value, subKey)}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={1}>
                                            <IconButton aria-label="delete" onClick={() => removeEntry(index, subKey)}>
                                                <CloseIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <Divider />
                        </React.Fragment>
                    ))
                ) : (
                    <React.Fragment key={index}>
                        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: "10px", paddingTop: "10px" }}>
                            <Grid item xs={4}>
                                <TextField
                                    value={item.key}
                                    onChange={(e) => handleKeyChange(index, e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={item.value['100g']}
                                    onChange={(e) => handleValueChange(index, '100g', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={item.value.serving}
                                    onChange={(e) => handleValueChange(index, 'serving', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={item.value.daily}
                                    onChange={(e) => handleValueChange(index, 'daily', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={1}>
                                <IconButton aria-label="delete" onClick={() => removeEntry(index)}>
                                    <CloseIcon color="error" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                    </React.Fragment>
                )
            ))}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton aria-label="add" onClick={addNewEntry} sx={{ marginTop: 2 }}>
                    <AddIcon color="action" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default LabelPreview;
