import * as React from 'react';
import {FC} from 'react';
import {GridOverlay} from '@mui/x-data-grid';
import {Box, useTheme} from "@mui/material";
import {useTranslation} from 'react-i18next';


export const DataGridNoRowsOverlay: FC<{}> = () => {
    const theme = useTheme();
    const {t} = useTranslation();


    return (
        <GridOverlay>
            <Box sx={{my: theme.spacing(8)}}>{t('datagrid:noResults')}</Box>
        </GridOverlay>
    );
}
