import {AppBar, Box, Toolbar, Grid, IconButton, Stack} from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {Link, Outlet} from "react-router-dom";
import theme from "../../../themes/default";
import React, {useState} from "react";
import Image from "../../Image";
import MobileNav from "../../navigation/MobileNav";
import DesktopNav from "../../navigation/DesktopNav";
import {useGetMeQuery} from "../../../redux/api/users";
import {Snackbar} from "../../ui/Snackbar";
import {SalesOrderBarcodeForm} from "./salesorders/SalesOrderBarcodeForm";

const Layout = () => {
    const [open, setOpen] = useState(false);
    const {data: currentUser, isSuccess} = useGetMeQuery();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{background: "#F8F6F2"}}>
            <AppBar
                elevation={0}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    borderBottom: "1px solid #EFECE7",
                    boxShadow: "none",
                    borderRadius: 0,
                    marginLeft: "300px",
                    width: "100%",
                    [theme.breakpoints.up('md')]: {
                        width: "calc(100% - 300px)",
                    }
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: 'space-between',
                        [theme.breakpoints.up('md')]: {
                            justifyContent: 'end',
                        },
                        color: theme.palette.text.primary,
                        gap: 6
                    }}
                >
                    <Box component={Link} to="/application/scanorder" sx={{display: {xs: 'block', md: 'none'}}}>
                        <Image
                            alt="logo here"
                            src={process.env.PUBLIC_URL + '/img/logo.svg'}
                            height={30}
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}
                        />
                    </Box>

                    <Stack direction={"row"} gap={5}>
                        <Box hidden
                             display={{
                                 md: 'flex',
                                 color: "#ffffff"
                            }}
                         >
                            <PersonOutlineOutlinedIcon sx={{ marginRight: "5px" }}/>
                            {isSuccess && currentUser!.name}
                        </Box>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleOpen}
                            sx={{
                                display: {
                                    0: "block",
                                    md: "none"
                                },
                            }}

                        >
                            Menu
                        </IconButton>
                    </Stack>
                </Toolbar>
                <MobileNav
                    fullName={isSuccess && currentUser ? currentUser.name : ''}
                    open={open}
                    onClose={handleClose}
                />
            </AppBar>
            <Grid
                container
                height={{
                    xs: "100vh",
                }}
            >
                <Grid
                    bgcolor="#FFFFFF"
                    height={{
                        xs: "100vh",
                    }}
                    hidden
                    item
                    flexDirection={"column"}
                    left={0}
                    position={"fixed"}
                    px={0}
                    width={"300px"}
                    zIndex={1200}
                    display={{
                        md: 'flex'
                    }}

                >
                    <Box
                        component={Link}
                        to="/application/scanorder"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "64px",
                            borderBottom: "1px solid #EFECE7"
                        }}
                    >
                        <Image
                            alt="Prometeus"
                            src={process.env.PUBLIC_URL + '/img/logo_alternate.svg'}
                            height={ 25 }
                            sx={{ height: "max-content" }}
                        />
                    </Box>

                   <Stack
                       flexGrow="1"
                       flexDirection="column"
                   >
                       <DesktopNav />
                   </Stack>
                </Grid>
                <Grid
                    flexGrow={1}
                    item
                    px={{
                        xs: 1,
                        md: 5
                    }}
                    pb={{ xs: 5 }}
                    ml={{
                        0: 0,
                        md: '300px'
                    }}
                    bgcolor={"#F8F6F2"}
                    sx={{
                        width: "calc(100% - 300px)",
                    }}
                >
                    <Grid
                        container
                        flexDirection={"column"}
                        alignItems={"center"}
                        height={"100%"}
                        justifyContent={"space-between"}
                    >
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "baseline",
                                margin:"100px 0 75px",
                                width:"100%",
                                height: "100%",
                            }}
                        >
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
                <SalesOrderBarcodeForm
                    style={{
                        position: "fixed",
                        top: 'auto',
                        bottom: 0,
                        backgroundColor: "#EFECE7",
                        boxShadow: "none",
                        borderRadius: 0,
                        marginLeft: "300px",
                        padding: "15px 0",
                        width: "100%",
                        [theme.breakpoints.up('md')]: {
                            width: "calc(100% - 300px)",
                        }
                    }}
                />
            </Grid>
            <Snackbar />

        </Box>
    );
};

export default Layout;
