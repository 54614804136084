import React, {FC, ReactNode, useEffect, useRef, useState} from "react";
import {
    DataGrid as MuiDataGrid,
    DataGridProps as MuiDataGridProps,
    GridFooter
} from '@mui/x-data-grid';
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setDataGridSelection} from "../../redux/slices/datagrid";
import {DataGridNoRowsOverlay} from "./DataGridNoRowsOverlay";
import {DataGridToolbar, DataGridToolbarAction} from "./DataGridToolbar";
import {styled} from "@mui/material";
import {useTranslation} from "react-i18next";

export type DataGridProps = MuiDataGridProps & {
    actions?: Array<DataGridToolbarAction>,
    filterComponent?: ReactNode,
    onSelectionChanged?: (selection: Array<string | number>) => void,
    ref?: (ref: HTMLDivElement|null) => void,
};

export const DataGrid: FC<DataGridProps> = ({actions, filterComponent, onSelectionChanged, initialState, ...props}) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
    const [horizontalScrollEndReached, setHorizontalScrollEndReached] = useState(false);
    const [horizontalScrollStartReached, setHorizontalScrollStartReached] = useState(true);

    const tableRef = useRef<HTMLDivElement|null>();

    let debounce: NodeJS.Timeout|undefined;

    const handleResize = () => {
        debounce && clearTimeout(debounce);

        if (tableRef.current === null || typeof tableRef.current === 'undefined') {
            return;
        }

        const scrollElement = tableRef.current?.children[1]?.children[0];
        if (typeof scrollElement === 'undefined') {
            return;
        }

        debounce = setTimeout(() => {
            setHasHorizontalScroll((scrollElement.scrollWidth - 25) > scrollElement.clientWidth);
        }, 500);
    };

    const registerScrollListener = () => {
        if (tableRef.current === null || typeof tableRef.current === 'undefined') {
            return;
        }

        const scrollElement = tableRef.current?.children[1]?.children[1];
        if (typeof scrollElement === 'undefined') {
            return;
        }

        scrollElement.addEventListener('scroll', handleScroll);
    };

    const handleScroll = () => {
        if (tableRef.current === null || typeof tableRef.current === 'undefined') {
            return;
        }

        const scrollElement = tableRef.current?.children[1]?.children[1];
        if (typeof scrollElement === 'undefined') {
            return;
        }

        setHorizontalScrollStartReached(scrollElement.scrollLeft <= 30);
        setHorizontalScrollEndReached((scrollElement.scrollLeft + scrollElement.clientWidth) >= (scrollElement.scrollWidth - 30));
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            tableRef?.current?.children[1]?.children[1]?.removeEventListener('resize', handleScroll);
        };
    }, [tableRef]);

    Object.assign(props.sx ?? {}, {
        '&.hasHorizontalScroll .MuiDataGrid-virtualScroller >:before': {
            opacity: horizontalScrollStartReached ? 0 : 1,
        },
    });

    Object.assign(props.sx ?? {}, {
        '&.hasHorizontalScroll .MuiDataGrid-virtualScroller >:after': {
            opacity: horizontalScrollEndReached ? 0 : 1,
        }
    });

    return (
        <StyledDataGrid
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            rowHeight={70}
            localeText={{
                MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                        `${from} - ${to} ${t('datagrid:of')} ${count}`,
                    labelRowsPerPage: t('datagrid:rowsPerPage'),
                },
            }}
            initialState={{
                ...initialState,
                pagination: {
                    paginationModel: {
                        pageSize: 25,
                    }
                }
            }}
            pageSizeOptions={[25, 50, 75, 100]}
            slots={{
                noRowsOverlay: DataGridNoRowsOverlay,
                toolbar: () => <DataGridToolbar key={'datagrid-toolbar'} actions={actions} filterComponent={filterComponent}/>,
                footer: () => <GridFooter />,
            }}
            onRowSelectionModelChange={selection => {
                dispatch(setDataGridSelection(selection));
                onSelectionChanged && onSelectionChanged(selection);
            }}
            className={hasHorizontalScroll ? 'hasHorizontalScroll' : ''}
            {...props}

            ref={(ref) => {
                tableRef.current = ref;
                handleResize();
                registerScrollListener();

                props.ref && props.ref(ref);
            }}
        />
    );
}

const StyledDataGrid = styled(MuiDataGrid)(( {theme }) => ({
    '& .MuiDataGrid-columnHeader': {
        padding: '0 24px',
        borderRadius: '0px',

        '&:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon': {
            opacity: 1,
        }
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F8F6F2',
        color: 'black',
        borderRadius: '0px',

        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 700,
        },
    },
    '& .MuiDataGrid-columnHeaders .MuiCheckbox-root': {
        color: '#FFFFFF',
        fontWeight: 700,
    },

    '& .MuiDataGrid-cell': {
        padding: '0 24px',
    },

    '& .MuiDataGrid-withBorderColor': {
        borderColor: theme.palette.divider,
    },

    '& .MuiDataGrid-row': {
        backgroundColor: '#FFFFFF',

        '&:hover':{
            backgroundColor: theme.palette.primary.light,
        }
    },
    '& .MuiDataGrid-footerContainer': {
        backgroundColor: '#FFFFFF',
    },
    '& .MuiDataGrid-iconButtonContainer': {
        marginLeft: '5px',

        '& .MuiDataGrid-sortIcon': {
            color: '#FFFFFF',
        },
    },
    '&.hasHorizontalScroll': {
        borderLeft: 'none',
        borderRight: 'none',
        minWidth: '100%',
    },
    '&.hasHorizontalScroll .MuiDataGrid-virtualScroller': {
        position: 'relative',
    },
    '&.hasHorizontalScroll .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerRenderZone': {
        top: 0,
    },
    '&.hasHorizontalScroll .MuiDataGrid-virtualScroller >:after': {
        position: 'sticky',
        content: '""',
        display: 'inline-block',
        top: 0,
        left: 'calc(100% - 8px)',
        height: '100%',
        width: 6,
        background: '#rgba(0,0,0,.4)',
        filter: 'blur(3px)',
    },
    '&.hasHorizontalScroll .MuiDataGrid-virtualScroller >:before': {
        position: 'sticky',
        zIndex: 100,
        content: '""',
        display: 'inline-block',
        top: 1,
        left: 0,
        height: '100%',
        width: 6,
        background: 'rgba(0,0,0,.4)',
        filter: 'blur(3px)',
    },
}));
