import React, {FC, ReactNode} from "react";
import {GridToolbarContainer} from "@mui/x-data-grid";
import {useAppSelector} from "../../hooks/useAppSelector";
import {Button, Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Stack} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {toggleShowFilters} from "../../redux/slices/datagrid";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import theme from "../../themes/default";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/sharp-regular-svg-icons/faBarsFilter';
import Typography from "@mui/material/Typography";
import {useTranslation} from 'react-i18next';

export type DataGridToolbarAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type DataGridToolbarProps = {
    actions?: Array<DataGridToolbarAction>,
    style?: React.CSSProperties,
    filterComponent?: ReactNode,
};

export const DataGridToolbar: FC<DataGridToolbarProps> = ({actions, filterComponent, style}) => {
    const {t} = useTranslation();
    const selectedRows = useAppSelector(state => state.datagrid.selected);
    const dispatch = useAppDispatch();
    const showFilters = useAppSelector(state => state.datagrid.showFilters);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer style={style} sx={{my:2, mx:2}}>
            <Grid item xs={12}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                </Stack>
                    {filterComponent && (
                    <Box sx={{
                        pt: 2,
                        pb: 2,
                    }}>
                        {filterComponent}
                    </Box>
                )}
            </Grid>
            <Grid item sm={12}>
            {actions && 0 < actions.length && (
                <>
                    <Button disabled={0 === selectedRows.length} onClick={handleClick} size="small" variant="outlined" sx={{ ml: '16px', mr: 2, mt: '7px' }}>
                        Bulk acties <KeyboardArrowDownOutlinedIcon sx={{ml: 1}} />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        {actions.map(action =>
                            <MenuItem key={action.label} onClick={() => action.onClick()}>
                                {action.icon && (
                                    <ListItemIcon>
                                        {action.icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText>{action.label}</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </>
            )}
            </Grid>
        </GridToolbarContainer>
    );
}
