import api from "./api";
import KeyValue from "../../types/KeyValue";
import {GetEmailsResponse} from "../../types/api/responses/settings/GetEmailsResponse";
import GetPathValueResponse from "../../types/api/responses/settings/GetPathValueResponse";

const settingsApi = api.injectEndpoints({
    endpoints: build => ({
        getSettings: build.query<KeyValue<string>, void>({
            query: () => ({
                url: 'api/settings',
            })
        }),

        getSetting: build.query<GetPathValueResponse, {path: string}>({
            query: (setting) => ({
                url: `/api/settings/${setting.path}`,
                method: 'GET',
            })
        }),

        saveSettings: build.mutation<boolean, KeyValue<string>>({
            query: (body) => ({
                url: 'api/settings',
                method: 'POST',
                body,
            })
        }),

        getEmails: build.query<GetEmailsResponse, void>({
            query: () => ({
                url: 'api/settings/emails',
            }),
        }),
    }),
});

export const {
    useGetSettingsQuery,
    useGetSettingQuery,
    useSaveSettingsMutation,
    useGetEmailsQuery,
} = settingsApi;

export default settingsApi;
