import { Box, SxProps } from '@mui/system';

type LogoProps = {
    src: string;
    alt: string;
    height?: number;
    width?: number;
    maxWidth?: number;
    maxHeight?: number;
    sx?: SxProps;
    borderRadius?: string;
};

const Image = ({ src, alt, sx, height, maxHeight, width, maxWidth, borderRadius }: LogoProps) => {
    return (
        <Box
            sx={sx}
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            borderRadius={borderRadius}
        >
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                style={{width: "100%", display: "block", borderRadius: borderRadius }}
            />
        </Box>
    );
};

export default Image;