import React, {FC} from 'react';
import {Field, FormikProps, FormikValues} from "formik";
import {Alert, CircularProgress, FormLabel, Stack,} from "@mui/material";
import {useGetEmailsQuery} from "../../../../redux/api/settings";
import {AppAccordion} from "../../../ui/AppAccordion";
import {Switch, TextField} from "formik-mui";
import {useTranslation} from "react-i18next";
import {WysiwygField} from "../../../form/WysiwygField";

export type EmailsFormProps = {
    formik: FormikProps<FormikValues>
}

export const EmailsForm: FC<EmailsFormProps> = ({formik}) => {
    const [t] = useTranslation();
    const {data: emails, isLoading: isEmailsLoading} = useGetEmailsQuery();

    return (
        <>
            {isEmailsLoading && (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress />
                </div>
            )}
            {emails && emails.emails.map((email, index) => (
                <AppAccordion title={email.description} sx={{mb: 1}} key={index}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Field
                            component={Switch}
                            type="checkbox"
                            name={`email/${email.code}/enabled`}
                            label={t('form:enabled')}
                            color={"success"}
                            onChange={ () => {
                                formik.setFieldValue(`email/${email.code}/enabled`, !formik.values[`email/${email.code}/enabled`]);
                            }}
                        />
                        <label>{t('form:enabled')}</label>
                    </Stack>

                    {(true === formik.values[`email/${email.code}/enabled`] || '1' === formik.values[`email/${email.code}/enabled`]) && (
                        <Stack direction={"column"} spacing={2}>
                            <Alert severity="info" sx={{margin: '8px 0'}}>
                                {t('form:availableVariables')}
                                <ul>
                                    {email.availableVariables.map((variable,index) => (
                                        <li key={index}>{`{{ ${variable.code} }}: ${variable.description}`}</li>
                                    ))}
                                </ul>
                            </Alert>

                            <Field
                                fullWidth
                                component={TextField}
                                type="text"
                                label={t('form:subject')}
                                name={`email/${email.code}/subject`}
                                required
                            />

                            <FormLabel>{t('form:content')}</FormLabel>
                            <WysiwygField
                                formik={formik}
                                name={`email/${email.code}/content`}
                            />
                        </Stack>
                    )}
                </AppAccordion>
            ))}
        </>
    )
}
