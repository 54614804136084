import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import SkuResponse from "../../types/api/responses/sku/SkuResponse";
import {SkusResponse} from "../../types/api/responses/sku/SkusResponse";
import {CreateSkuRequest} from "../../types/api/requests/CreateSkuRequest";

const skusApi = api.injectEndpoints({
    endpoints: build => ({
        getSkusCollection: build.query<SkusResponse, DynamicListRequest>({
            query: (request) =>  UrlHelper.buildUrl('/api/skus/', request),
        }),

        getSkusByLabelIdCollection: build.query<SkusResponse, {id: number} & DynamicListRequest>({
            query: (request) => ({
                url: UrlHelper.buildUrl(`/api/skus?labels.id=${request.id}`, request),
            }),
            providesTags: ['skuList'],
        }),

        getSkusWithoutLabelsCollection: build.query<SkusResponse, DynamicListRequest>({
            query: (request) =>  UrlHelper.buildUrl('/api/skus/skus_without_labels', request),
        }),

        getSku: build.query<SkuResponse, {id: number}>({
            query: (sku) => ({
                url: `/api/skus/${sku.id}`,
                method: 'GET',
            })
        }),

        createSku: build.mutation<SkuResponse, CreateSkuRequest>({
            query: (sku) => ({
                url: '/api/skus',
                method: 'POST',
                body: sku,
            })
        })
    }),
});

export const {
    useGetSkusCollectionQuery,
    useGetSkusWithoutLabelsCollectionQuery,
    useGetSkusByLabelIdCollectionQuery,
    useGetSkuQuery,
    useCreateSkuMutation,
} = skusApi;
export default skusApi;
