import React, {FC, useRef, useState} from 'react';
import {GridColDef} from "@mui/x-data-grid";
import {SalesOrdersFilter} from "./salesorders/SalesOrdersFilter";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../datagrid/DynamicDataGrid";
import {Helmet} from "react-helmet-async";
import {Divider, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {useGetSalesOrderCollectionQuery} from "../../../redux/api/salesorders";
import {DateTimeRenderer} from "../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../themes/default";

const SalesOrders: FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [currentFilters, setCurrentFilters] = useState<RequestFilter>([]);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);


    const handleCellClick = (params:any, event:any) => {
        navigate(`/application/salesorders/details/${params.row.id}`, { state: { id: params.row.id} });
    };

    const columns: GridColDef[] = [
        { field: 'orderId', headerName: t('datagrid:salesOrder:orderId'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => params.row.orderId },
        { field: 'amountOfSkus', headerName: t('datagrid:salesOrder:skus'), editable: false, flex: 1, minWidth: 200},
        { field: 'status', headerName: t('datagrid:salesOrder:status'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => t(`datagrid:salesOrder:${params.row.status}`) },
        { field: 'countryCode', headerName: t('datagrid:salesOrder:countryCode'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => params.row.countryCode },
        { field: 'createdAt', headerName: t('datagrid:salesOrder:createdAt'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => <DateTimeRenderer date={params.row.createdAt} /> }
    ];

    let filteredColumns: GridColDef<any, any, any>[] = [];
    filteredColumns = columns.map(column => ({ ...column }));

    return (
        <>
            <Helmet>
                <title>{t('helmet:salesOrders')}</title>
            </Helmet>
            <Paper
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.primary.light}`
                }}
            >
                <Grid container>
                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                        <Grid item xs={12} sm={6} >
                            <Typography variant="h5" py={0.75}>
                                {t('pageTitle:salesOrders')}
                            </Typography>
                        </Grid>
                    </Stack>

                    <Divider sx={{width: "100%"}} />

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                        }}
                        filter={ [...currentFilters, ...[{field: 'labelsProcessed', value: '1'}]] }
                        filterComponent={<SalesOrdersFilter onFilterChanged={(filters: any) => setCurrentFilters(filters)} />}
                        ref={gridRef}
                        columns={filteredColumns}
                        queryHook={useGetSalesOrderCollectionQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        onCellClick={handleCellClick}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Grid>
                <Snackbar />
            </Paper>
        </>
    );
};

export default SalesOrders;