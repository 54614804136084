import {Box, Button} from '@mui/material';
import * as React from "react";
import {FC} from "react";
import {AppDialog, AppDialogProps} from "./AppDialog";

type ConfirmDialogProps = Omit<AppDialogProps, 'open'|'onClose'> & {
    message: string;
    onYes: () => void;
    onNo: () => void;
    onClose?: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
    open?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
    cancelLabel,
    confirmLabel,
    title,
    message,
    onClose,
    onNo,
    onYes,
    open = true
}) => {
    return (
        <AppDialog open={open} onClose={onClose || onNo} title={title} sx={{textAlign: 'center'}}>
            { message }
            <Box sx={{display: 'flex', justifyContent: 'center', mt: '22px'}}>
                <Button autoFocus onClick={onNo} variant={'outlined'} sx={{marginRight: 1}}>{cancelLabel || 'Nee'}</Button>
                <Button variant="contained" onClick={onYes}>{confirmLabel || 'Ja'}</Button>
            </Box>
        </AppDialog>
    );
}
