import {TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {

    fontFamily: "Inter, sans-serif",

    h2: {
        fontSize: 18,
        fontWeight: 700,
        color: "#373640",
    },

    h6: {
        fontSize: 18,
        color: "#373640",
        fontWeight: "700",
    }
    // myVariant: {
    //     fontSize: 10
    // }
};

export default typography;
