import React, {FC, useEffect} from "react";
import {clearSnackbar} from "../../redux/slices/layout";
import {Alert, Snackbar as MuiSnackbar} from "@mui/material";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useAppDispatch} from "../../hooks/useAppDispatch";

export const Snackbar: FC<{}> = () => {
    const snackbar = useAppSelector(state => state.layout.snackbar);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(clearSnackbar());
        }, 6000);

        return () => clearTimeout(timeout);
    })

    return (
        <>
            {snackbar && (
                <MuiSnackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={true}
                    onClose={() => dispatch(clearSnackbar())}
                >
                    <Alert variant="filled" onClose={() => dispatch(clearSnackbar())} severity={snackbar.type} sx={{width: '100%'}}>
                        {snackbar.message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
}
