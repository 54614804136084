import React, {FC} from 'react';
import {Field} from "formik";
import {SettingsCard} from "./SettingsCard";
import {TextField} from "formik-mui";
import {useTranslation} from "react-i18next";
import {useGetSettingQuery} from "../../../../redux/api/settings";
import {Alert} from "@mui/material";
import {DateTimeRenderer} from "../../../datagrid/renderers/DateTimeRenderer";

export const RetailServiceForm: FC<{}> = () => {
    const [t] = useTranslation();
    const {data: orderUpdate, isLoading: isOrderUpdateLoading} = useGetSettingQuery({path: 'retail_service/api_import_order_schedule'});

    return (
        <>
            <SettingsCard title={t('settings:retailServiceSettings:apiSettings')}>
                { !isOrderUpdateLoading && orderUpdate?.value &&
                    <Alert severity="info">
                        { `Last import: ${orderUpdate.value} ${t('settings:since')} `}
                        <DateTimeRenderer date={ orderUpdate.updatedAt.date } />
                    </Alert>
                }
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:retailServiceSettings:apiPublicKey')}
                    name="retail_service/api_public_key"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:retailServiceSettings:apiSecretKey')}
                    name="retail_service/api_secret_key"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:retailServiceSettings:apiCompanyKey')}
                    name="retail_service/api_company_key"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:retailServiceSettings:apiUsername')}
                    name="retail_service/api_username"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="password"
                    label={t('settings:retailServiceSettings:apiPassword')}
                    name="retail_service/api_password"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:retailServiceSettings:apiAdministrationId')}
                    name="retail_service/api_administration_id"
                    InputLabelProps={{ shrink: true }}
                    required
                />
            </SettingsCard>
        </>
    );
}
