import React, {useRef, useState} from "react";
import {Button, Divider, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import theme from "../../../themes/default";
import {
    useGetLabelsCollectionQuery,
    useLazyGetExportDownloadQuery
} from "../../../redux/api/labels";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../datagrid/DynamicDataGrid";
import {useNavigate} from "react-router-dom";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {GridColDef} from "@mui/x-data-grid";
import {DateTimeRenderer} from "../../datagrid/renderers/DateTimeRenderer";
import {LabelsFilter} from "./labels/LabelsFilter";
import useSecurity from "../../../hooks/useSecurity";
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import {FormDialog} from "../../dialogs/FormDialog";
import {CreateLabelForm} from "./labels/CreateLabelForm";
import PostAddIcon from '@mui/icons-material/PostAdd';
import {useDeleteSourceMutation, useGetSourcesCollectionQuery} from "../../../redux/api/source";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrashCan} from "@fortawesome/sharp-regular-svg-icons";
import {RowActions} from "../../datagrid/RowActions";
import {ConfirmDialog} from "../../dialogs/ConfirmDialog";

const Labels = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const hasRole = useSecurity();
    const [currentFilters, setCurrentFilters] = useState<RequestFilter>([]);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<any|null>(null);
    const [getExportDownload] = useLazyGetExportDownloadQuery();
    const [deleteSource] = useDeleteSourceMutation();
    const {data: allLabels, isLoading: isLoadingAllLabels } = useGetLabelsCollectionQuery({page: 1, pageSize: 100, sort: [{field: 'name', sort: 'ASC'}]});

    const [formOpen, setFormOpen] = useState(false);

    const handleCloseFormDialog = () => {
        setFormOpen(false);
    }

    const handleCellClick = async (params:any, event:any) => {
        if (
            event.currentTarget.dataset.field === 'actions' ||
            params.row.labels.length === 0
        ) {
            return;
        }

        navigate(`/application/labels/details/${params.row.id}`, { state: { id: params.row.id} });
    };

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteSource(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }

    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    const submitExportRequest = async () => {
        try {
            const result = await getExportDownload('').unwrap();
            window.location.href = result.url;
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    const labelColumns: GridColDef[] = [
        { field: 'skus', headerName: t('datagrid:labels:skus'), editable: false, flex: 1, minWidth: 175, renderCell: (params) => {
                if(params.row.skus.length > 5) {
                    return `${params.row.skus.length} ${t('datagrid:labels:skus')}`;
                } else {
                    return params.row.skus.map((sku: any) => sku.sku).join(', ');
                }
            }
        },
        { field: 'labels', headerName: t('datagrid:labels:labels'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => params.row.labels.length },
        { field: 'createdAt', headerName: t('datagrid:labels:createdAt'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => <DateTimeRenderer date={params.row.createdAt} /> },
        {
            field: 'actions',
            headerName: t('datagrid:actions'),
            headerAlign: "left",
            width: 125,
            align: "left",
            renderCell: (params) => {
                const actions = [];

                actions.push({
                    icon: <FontAwesomeIcon height={16} width={16} icon={faPen} style={{pointerEvents: 'none'}} />,
                    label: t('datagrid:edit'),
                    onClick: () => {
                        navigate(`/application/labels/edit/${params.row.id}`)
                    }
                });

                actions.push({
                    icon: <FontAwesomeIcon height={16} width={16} icon={faTrashCan} style={{color: theme.palette.secondary.main, pointerEvents: 'none'}} />,
                    label: t('datagrid:delete'),
                    onClick: async () => {
                        setSelectedForDeleting(params.row);
                    }
                });

                return <RowActions actions={actions}/>;
            },
        },
    ];

    let filteredColumns: GridColDef<any, any, any>[] = [];
    const memberColumns = ['skus', 'createdAt', 'labels'];
    const adminColumns = [...memberColumns, 'actions'];

    if (hasRole('ROLE_ADMIN')) {
        filteredColumns = labelColumns
            .filter(column => adminColumns.includes(column.field))
            .map(column => ({ ...column }));
    } else if (hasRole('ROLE_MEMBER')) {
        filteredColumns = labelColumns
            .filter(column => memberColumns.includes(column.field))
            .map(column => ({...column}));
    }

    return (
        <>
            <Helmet>
                <title>{t('helmet:labels')}</title>
            </Helmet>
            <Paper
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.primary.light}`
                }}
            >
                <Grid container>
                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                        <Grid item xs={6}>
                            <Typography variant="h5" py={0.75}>
                                {t('pageTitle:labels')}
                            </Typography>
                        </Grid>
                        {hasRole('ROLE_ADMIN') && (
                            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size={'large'}
                                        sx={{
                                            background: "#01C677",
                                            height: 48,
                                            width: {
                                                xs: 48,
                                                md: "auto"
                                            },
                                            p: {
                                                xs: 0,
                                                md: "15px",
                                            },
                                            minWidth:48
                                        }}
                                        onClick={() => setFormOpen(true)}
                                    >
                                        <PostAddIcon sx={{ marginRight: "5px" }} />
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    md: "inline"
                                                }
                                            }}
                                            fontWeight={700}
                                            fontSize={13}
                                        >
                                            {t('labels:createLabel')}
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Grid item ml={2} textAlign={'right'}>
                                    <Button
                                        variant="contained"
                                        size={'large'}
                                        onClick={ submitExportRequest }
                                        sx={{
                                            height: 48,
                                            width: {
                                                xs: 48,
                                                md: "auto"
                                            },
                                            p: {
                                                xs: 0,
                                                md: "15px",
                                            },
                                            minWidth:48
                                        }}
                                        disabled={!isLoadingAllLabels && allLabels && allLabels['hydra:totalItems'] === 0}
                                    >
                                        <ArchiveOutlinedIcon sx={{ marginRight: "5px" }} />
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    md: "inline"
                                                }
                                            }}
                                            fontWeight={700}
                                            fontSize={13}
                                        >
                                            {t('button:export')}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Stack>

                    <Divider sx={{width: "100%"}} />

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'productId', sort: 'asc' }],
                            },
                        }}
                        filter={currentFilters}
                        filterComponent={<LabelsFilter onFilterChanged={(filters: any) => setCurrentFilters(filters)} />}
                        ref={gridRef}
                        columns={filteredColumns}
                        queryHook={useGetSourcesCollectionQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        onCellClick={handleCellClick}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Grid>
                <Snackbar />
            </Paper>

            <FormDialog
                open={formOpen}
                close={handleCloseFormDialog}
                title={t('dialog:createLabel')}
                maxWidth={ false }
            >
                <CreateLabelForm/>
            </FormDialog>

            {selectedForDeleting && (
                <ConfirmDialog
                    cancelLabel={t('button:cancel')}
                    confirmLabel={t('button:delete')}
                    title={t('dialog:deleteLabel')}
                    message={t('dialog:confirmDeleteLabel')}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}
        </>
    );
};

export default Labels;
