import useBase64EncodedToBlob from "./useBase64EncodedToBlob";

const useSendLabelsToPrinter = () => {
    const base64ToBlob = useBase64EncodedToBlob();

    return async (printUrl: string, pdfData: string) => {
        const data = new FormData()
        data.append('pdf', base64ToBlob(pdfData));

        fetch(printUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: data,
        });
    };
}

export default useSendLabelsToPrinter;