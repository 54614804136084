import NotificationHelper from "../helpers/NotificationHelper";
import ApiErrorHelper from "../helpers/ApiErrorHelper";
import {useLazyGetLabelsToPrintQuery} from "../redux/api/salesorders";
import {useGetMeQuery} from "../redux/api/users";
import {useTranslation} from "react-i18next";
import usePrintLocation from "./usePrintLocation";
import useSendLabelsToPrinter from "./useSendLabelsToPrinter";

const useSendPrintCommand = () => {
    const {t} = useTranslation();
    const printLocation = usePrintLocation();
    const sendLabelsToPrinter = useSendLabelsToPrinter();
    const [getLabelData] = useLazyGetLabelsToPrintQuery();
    const {data: currentUser, isLoading: isCurrentUserLoading} = useGetMeQuery();

    return async (salesOrderId: number) => {
        try {
            const response = await getLabelData({id: salesOrderId}).unwrap();

            if(response.a6) {
                const printURL = printLocation(currentUser, 'a6');
                sendLabelsToPrinter(printURL, response.a6);
            }

            if(response.a8) {
                const printURL = printLocation(currentUser, 'a8');
                sendLabelsToPrinter(printURL, response.a8);
            }

            NotificationHelper.showSuccess(t('notification:sentLabelsToPrinter'));
        } catch (error) {
            ApiErrorHelper.processErrors({
                status: 400,
                data: {
                    message: t('notification:notAbleToPrint')
                }
            });
        }
    }

}

export default useSendPrintCommand;