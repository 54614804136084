import {createSlice, Draft} from "@reduxjs/toolkit";
import authApi from "../api/auth";
import usersApi from "../api/users";

interface AuthState {
    token: string|null,
    currentUserId?: number,
    currentRoles?: string[],
}

const initialState: AuthState = {
    token: null,
}

/**
 * Authentication actions.
 */
export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state: Draft<AuthState>, action) => {
            state.token = action.payload;
        },
        logout: (state: Draft<AuthState>) => {
            state.token = initialState.token;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state: Draft<AuthState>, { payload }) => {
                state.token = payload.token;
            }
        );
        builder.addMatcher(
            usersApi.endpoints.getMe.matchFulfilled,
            (state: Draft<AuthState>, { payload }) => {
                state.currentUserId = payload.id;
                state.currentRoles = payload.roles;
            }
        );
    }
});

export const {login, logout} = auth.actions;
