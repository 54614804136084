import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export type labelState = {
    id: number;
    sourceData: object;
    skus: Array<string>;
    dimensions: Array<string>;
}

const initialState: labelState = {
    id: 0,
    sourceData: {
        "nutrition_facts": {
            "per_100_gram": {
                "Energie": "375 kcal/1571 kJ",
                "Vetten": {
                    "Totaal": "1.4 g",
                    "Verzadigde vetzuren": "0.46 g"
                },
                "Koolhydraten": {
                    "Totaal": "75.5 g",
                    "Suikers": "5.6 g"
                },
                "Eiwitten": "14.9 g",
                "Zout": "0.37 g",
                "Vezels": "0.8 g",
                "Vitaminen": {
                    "Vitamine A": "90 mcg",
                    "Vitamine C": "60 mg",
                    "Vitamine E": "6.22 mg",
                    "Vitamine B1 (Thiamine)": "0.2 mg",
                    "Vitamine B2 (Riboflavine)": "0.1 mg",
                    "Niacine": "2.10 mg",
                    "Vitamine B6": "0.21 mg",
                    "Foliumzuur": "30 mcg",
                    "Vitamine B12": "0.36 mcg",
                    "Biotine": "16.5 mcg",
                    "Pantotheenzuur": "0.60 mg"
                },
                "Mineralen": {
                    "Kalium": "418.3 mg",
                    "Chloride ": "195.5 mg",
                    "Calcium": "105 mg",
                    "Fosfor": "50 mg",
                    "Magnesium": "24.9 mg",
                    "IJzer": "1.20 mg",
                    "Zink": "0.90 mg",
                    "Koper": "0.19 mg",
                    "Mangaan": "0.15 mg",
                    "Seleen": "8.18 mcg",
                    "Chroom": "10.8 mcg",
                    "Jodium": "15.27 mcg"
                },
            },
            "per_serving": {
                "Energie": "376 kcal/1571 kJ",
                "Vetten": {
                    "Totaal": "1.4 g",
                    "Verzadigde vetzuren": "0.46 g"
                },
                "Koolhydraten": {
                    "Totaal": "75.5 g",
                    "Suikers": "5.6 g"
                },
                "Eiwitten": "14.9 g",
                "Zout": "0.37 g",
                "Vezels": "0.8 g",
                "Vitaminen": {
                    "Vitamine A": "90 mcg",
                    "Vitamine C": "60 mg",
                    "Vitamine E": "6.22 mg",
                    "Vitamine B1 (Thiamine)": "0.2 mg",
                    "Vitamine B2 (Riboflavine)": "0.1 mg",
                    "Niacine": "2.10 mg",
                    "Vitamine B6": "0.21 mg",
                    "Foliumzuur": "30 mcg",
                    "Vitamine B12": "0.36 mcg",
                    "Biotine": "16.5 mcg",
                    "Pantotheenzuur": "0.60 mg"
                },
                "Mineralen": {
                    "Kalium": "418.3 mg",
                    "Chloride ": "195.5 mg",
                    "Calcium": "105 mg",
                    "Fosfor": "50 mg",
                    "Magnesium": "24.9 mg",
                    "IJzer": "1.20 mg",
                    "Zink": "0.90 mg",
                    "Koper": "0.19 mg",
                    "Mangaan": "0.15 mg",
                    "Seleen": "8.18 mcg",
                    "Chroom": "10.8 mcg",

                    "Jodium": "15.27 mcg"
                },
            },
        },

        "ingredients": "maltodextrine, eiwitmix (wei-eiwitconcentraat (MELK), calciumcaseïnaat (MELK), EI-eiwit), magere cacao, aroma's, mineralenmengsel (dikaliumfosfaat, natriumchloride, magnesiumfosfaat, ijzerpyrofosfaat, zinkoxide, koperchloride, mangaansulfaat, chroomchloride, kaliumjodide, natriummolybdaat, nikkellaat, seleniumchelaat), middellangeketentriglyceriden, mengsel van vitamines (dextrose monohydraat, antioxidanten (ascorbinezuur, alfa-tocoferol), niacinamide (B3), biotine, vitamine A-acetaat, calcium-d-pantothenaat, pyridoxinehydrochloride (B6), folic acid, thiamine mononitrate (B1), riboflavine (B2), vitamine D3, vitamine B12), verdikkingsmiddel (cellulosegom), zoetstof (acesulfaam K).",
        "dosage_and_use": "Meng 2 maatschepjes (334 g) met 710 ml water.",
        "additional_information": "Niet geschikt voor kinderen en adolescenten. Kan sporen bevatten van: noten, pinda's en soja.",
        "contents": "2727 gram (~8 doseringen)"
    },
    skus: [],
    dimensions: [],
}

const labelSlice = createSlice({
    name: "label",
    initialState,
    reducers: {
        set: (_state: Draft<labelState>, action: PayloadAction<labelState>) => ({
            ...action.payload
        }),
        setId: (_state: Draft<labelState>, action: PayloadAction<number>)=> ({
            ..._state, id: action.payload
        }),
        reset: () => ({
            ...initialState
        }),
    },
});

export default labelSlice;
