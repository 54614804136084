import React from 'react';
import {ThemeProvider, CssBaseline} from "@mui/material";
import theme from './themes/default';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";
import Router from "./components/pages/Router";
import LanguageProvider from "./i18n/LanguageProvider";
import {HelmetProvider} from "react-helmet-async";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function App() {

    const helmetContext = {};

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <LanguageProvider>
                    <HelmetProvider context={helmetContext}>
                        <Router/>
                    </HelmetProvider>
                </LanguageProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
  );
}

export default App;
