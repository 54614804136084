class ArrayHelper
{
    public get(sourceObject: any, dotNotationPath: string, defaultValue: string|boolean|number|any = ''): any {
        let returnData = sourceObject;

        dotNotationPath.split(".").forEach(subPath => {
            returnData = returnData[subPath] || defaultValue;
        });

        return returnData;
    }
}

const instance = new ArrayHelper();

export default instance;

