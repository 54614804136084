import React, {useEffect, useState} from 'react';
import {Alert, Typography, Button, Grid, Portal} from "@mui/material";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/sharp-regular-svg-icons/faArrowLeft';
import {TextField} from "../../../form/TextField";
import theme from "../../../../themes/default";
import PageTitle from "../../../../components/PageTitle";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import {useForgotPasswordMutation} from "../../../../redux/api/users";
import ForgotPasswordRequest from "../../../../types/api/requests/user/ForgotPasswordRequest";
import {useTranslation} from 'react-i18next';
import {Snackbar} from "../../../ui/Snackbar";

const ForgotPasswordForm = () => {

    const {t} = useTranslation();

    const [mounted, setMounted] = useState(false);
    const [forgotPassword] = useForgotPasswordMutation();
    const [success, setSuccess] = useState(false);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            const result = await forgotPassword(values as ForgotPasswordRequest).unwrap();
            setSuccess(result);
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: submit,
    });

    const linkStyle = {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
            <Helmet>
                <title>{t('helmet:forgotPassword')}</title>
            </Helmet>
            {mounted && (
                <Portal container={document.getElementById('pageTitle')} >
                    <PageTitle textPrimary={t('pageTitle:forgotPassword')} />
                </Portal>
                )}
                {success && (
                    <Alert severity="success" sx={{mb:3}}>
                        {t('notification:forgotPasswordSuccess')}
                    </Alert>
                )}
                {!success && (
                    <Typography mb={3}>
                        {t('form:forgotPasswordDescription')}
                    </Typography>
                )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        id="email"
                        variant="outlined"
                        label={t('form:email')}
                        name="email"
                        type="email"
                        formik={formik}
                    />
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    spacing={2}
                    justifyContent={"space-between"}
                >
                    <Grid item >
                        <Link to="/" title={t('button:backToLogin')} style={linkStyle}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                            {t('button:backToLogin')}
                        </Link>
                    </Grid>
                    <Grid item >
                        <Button type="submit" variant="contained">
                            {t('button:send')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
            <Snackbar />
        </>
    );
};

export default ForgotPasswordForm;
