import React, {FC, useRef, useState} from 'react';
import {Helmet} from "react-helmet-async";
import ResponsiveDataRow from "../../../ui/ResponsiveDataRow";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useGetSalesOrderQuery, useUpdatePrintLabelStatusMutation} from "../../../../redux/api/salesorders";
import {Box, Button, Card, CardContent, CardHeader, Container, Divider, Stack, Typography} from "@mui/material";
import {DateTimeRenderer} from "../../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../../themes/default";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../../datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {useGetSalesOrderLineByOrderIdCollectionQuery} from "../../../../redux/api/salesorderlines";
import {DataGridNoRowsOverlay} from "../../../datagrid/DataGridNoRowsOverlay";
import useSendPrintCommand from "../../../../hooks/useSendPrintCommand";
import {ToggleRenderer} from "../../../datagrid/renderers/ToggleRenderer";
import SalesOrderResponse from "../../../../types/api/responses/salesorder/SalesOrderResponse";
import {AppDialog} from "../../../dialogs/AppDialog";
import {Form, Formik} from "formik";

const SalesOrderDetails: FC = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const orderId = id as unknown as number;
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const {data} = useGetSalesOrderQuery({id: orderId});
    const [updatePrintLabelStatus] = useUpdatePrintLabelStatusMutation();
    const [selectedForPrintLabelToggling, setSelectedForPrintLabelToggling] = useState<(SalesOrderResponse & {printLabels: boolean})|null>(null);
    const [selectedForApplyLabelToggling, setSelectedForApplyLabelToggling] = useState<(SalesOrderResponse & {applyLabels: boolean})|null>(null);
    const sendPrintCommand = useSendPrintCommand();

    const printLabels = (salesOrderId: number) => {
        sendPrintCommand(salesOrderId);
    }

    const salesOrderData = [
        { label: t('form:orderId'), value: data?.orderId, parseHtml: false },
        { label: t('form:createdAt'), value: data?.createdAt ? <DateTimeRenderer date={data.createdAt} /> : null, parseHtml: false },
        { label: t('form:status'), value: t(`datagrid:salesOrder:${data?.status}`), parseHtml: false },
        { label: t('form:countryCode'), value: data?.countryCode, parseHtml: false },
        { label: t('form:description'), value: data && data.description ? data.description : t('datagrid:salesOrder:noDescription'), parseHtml: false },
        { label: t('form:notes'), value: data && data.notes ? data.notes : t('datagrid:salesOrder:noNotes'), parseHtml: true },
    ];

    const salesOrderLineColumns: GridColDef[] = [
        { field: 'sku.sku', headerName: t('datagrid:salesOrderLine:sku'), editable: false, minWidth: 125, renderCell: (params) => params.row.sku.sku },
        { field: 'sku.description', headerName: t('datagrid:salesOrderLine:skuDescription'), editable: false, flex: 1, minWidth: 400, renderCell: (params) => params.row.sku.description },
        { field: 'dimension', headerName: t('datagrid:salesOrderLine:dimension'), editable: false, minWidth: 150, renderCell: (params) => params.row.sku.dimension.toUpperCase() },
        { field: 'quantity', headerName: t('datagrid:salesOrderLine:quantity'), editable: false, minWidth: 150, renderCell: (params) => params.row.quantity },
    ];

    const handlePrintLabelToggleClick = (rowData: SalesOrderResponse, newStatus: boolean) => {
        setSelectedForPrintLabelToggling({...rowData, printLabels: newStatus});
    }

    const handlePrintLabelToggleConfirm = async () => {
        if (selectedForPrintLabelToggling) {
            await updatePrintLabelStatus({
                id: selectedForPrintLabelToggling.id,
                values: { printLabels: selectedForPrintLabelToggling.printLabels }
            }).unwrap();
            setSelectedForPrintLabelToggling(null);
        }
    }

    const handlePrintLabelToggleDecline = () => {
        setSelectedForPrintLabelToggling(null);
    }

    const handleApplyLabelToggleClick = (rowData: SalesOrderResponse, newStatus: boolean) => {
        setSelectedForApplyLabelToggling({...rowData, applyLabels: newStatus});
    }

    const handleApplyLabelToggleConfirm = async () => {
        if (selectedForApplyLabelToggling) {
            await updatePrintLabelStatus({
                id: selectedForApplyLabelToggling.id,
                values: { applyLabels: selectedForApplyLabelToggling.applyLabels }
            }).unwrap();
            setSelectedForApplyLabelToggling(null);
        }
    }

    const handleApplyLabelToggleDecline = () => {
        setSelectedForApplyLabelToggling(null);
    }

    return (
        <>
            <Helmet>
                <title>{`${t('helmet:salesOrderDetails')} - #${ data && data.orderId }`}</title>
            </Helmet>
            <Container sx={{width:"100%"}} maxWidth="xl" disableGutters>
                <Stack direction={"column"} spacing={3} sx={{ boxShadow: "none" }}>
                    <Card sx={{ boxShadow: "none", border: `1px solid ${theme.palette.primary.light}` }}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={3} sx={{ boxShadow: "none" }}>
                            <CardHeader
                                title={ `${t('cardTitle:salesOrder')}: #${data ? data.orderId : null}` }
                                sx={{
                                    py: 3,
                                    px: 4,
                                    color: theme.palette.text.primary
                                }}
                            />
                            <Box display="flex" alignItems="center" gap={6}>
                                {data &&
                                    <Box display="flex" alignItems="center" gap={6}>
                                        <Box>
                                            <Typography component="label">{t('datagrid:salesOrder:printLabels')}</Typography>
                                            <ToggleRenderer
                                                value={data?.printLabels}
                                                onToggle={(value) => handlePrintLabelToggleClick(data, value)}
                                                color={data?.printLabels ? 'success' : 'error'}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography component="label">{t('datagrid:salesOrder:apply')}</Typography>
                                            <ToggleRenderer
                                                value={data?.applyLabels}
                                                onToggle={(value) => handleApplyLabelToggleClick(data, value)}
                                                color={data?.applyLabels ? 'success' : 'error'}
                                            />
                                        </Box>
                                    </Box>
                                }
                                <Button
                                    variant="contained"
                                    size={'large'}
                                    sx={{
                                        background: "#01C677",
                                        height: 48,
                                        width: {
                                            xs: 48,
                                            md: "auto"
                                        },
                                        p: {
                                            xs: 0,
                                            md: "15px",
                                        },
                                        mr: '32px !important',
                                        minWidth:48
                                    }}
                                    onClick={ () => printLabels(orderId) }
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: {
                                                xs: "none",
                                                md: "inline"
                                            }
                                        }}
                                        fontWeight={700}
                                        fontSize={13}
                                    >
                                        {t('labels:printLabels')}
                                    </Typography>
                                </Button>
                            </Box>
                        </Stack>
                        <Divider/>
                        <CardContent>
                            <Box px={2}>
                                {salesOrderData.map((data) => (
                                    data.value && (
                                        <ResponsiveDataRow
                                            key={data.label}
                                            label={data.label}
                                            value={data.value}
                                            labelMinWidth={160}
                                            parseHtml={data.parseHtml}
                                        />
                                    )
                                ))}
                            </Box>
                        </CardContent>
                    </Card>

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'sku.printLabels', sort: 'asc' }],
                            },
                        }}
                        slots={{
                            noRowsOverlay: DataGridNoRowsOverlay,
                            toolbar: () => null,
                        }}
                        ref={gridRef}
                        columns={salesOrderLineColumns}
                        queryHook={useGetSalesOrderLineByOrderIdCollectionQuery}
                        extraRequestParams={{id: orderId}}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: "none",
                            border: `1px solid ${theme.palette.primary.light}`
                        }}
                    />
                </Stack>
            </Container>

            {selectedForPrintLabelToggling && (
                <AppDialog
                    title={t('dialog:togglePrintLabelsTitle', {
                        action: selectedForPrintLabelToggling.printLabels ? t('dialog:enable') : t('dialog:disable')
                    })}
                    open={true}
                    onClose={handlePrintLabelToggleDecline}
                >
                    <Box sx={{justifyContent: 'center'}}>
                        <Typography sx={{mb: 2, textAlign: 'center'}}>
                            {t('dialog:togglePrintLabelMessage', {
                                action: selectedForPrintLabelToggling.printLabels ? t('dialog:enable') : t('dialog:disable')
                            })}
                        </Typography>

                        <Formik initialValues={{}} onSubmit={handlePrintLabelToggleConfirm}>
                            {({ submitForm, isSubmitting }) => (
                                <Form>
                                    <Stack direction={'row'} gap={2} mt={6} justifyContent={'center'}>
                                        <Button
                                            variant="outlined"
                                            onClick={handlePrintLabelToggleDecline}
                                        >
                                            {t('cancel', { ns: 'button' })}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            {selectedForPrintLabelToggling.printLabels ? t('dialog:enable') : t('dialog:disable')}
                                        </Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </AppDialog>
            )}

            {selectedForApplyLabelToggling && (
                <AppDialog
                    title={t('dialog:toggleApplyLabelsTitle', {
                        action: selectedForApplyLabelToggling.applyLabels ? t('dialog:enable') : t('dialog:disable')
                    })}
                    open={true}
                    onClose={handleApplyLabelToggleDecline}
                >
                    <Box sx={{justifyContent: 'center'}}>
                        <Typography sx={{mb: 2, textAlign: 'center'}}>
                            {t('dialog:toggleApplyLabelMessage', {
                                action: selectedForApplyLabelToggling.applyLabels ? t('dialog:enable') : t('dialog:disable')
                            })}
                        </Typography>

                        <Formik initialValues={{}} onSubmit={handleApplyLabelToggleConfirm}>
                            {({ submitForm, isSubmitting }) => (
                                <Form>
                                    <Stack direction={'row'} gap={2} mt={6} justifyContent={'center'}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleApplyLabelToggleDecline}
                                        >
                                            {t('cancel', { ns: 'button' })}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            {selectedForApplyLabelToggling.applyLabels ? t('dialog:enable') : t('dialog:disable')}
                                        </Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </AppDialog>
            )}
        </>
    );
};

export default SalesOrderDetails;