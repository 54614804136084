import React, {useEffect, useState} from 'react';
import {Alert, Typography, Button, Grid, Portal} from "@mui/material";
import { Helmet } from 'react-helmet-async';

import {TextField} from "../../../form/TextField";

import theme from "../../../../themes/default";
import PageTitle from "../../../../components/PageTitle";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import {useResetPasswordMutation} from "../../../../redux/api/users";
import ResetPasswordRequest from "../../../../types/api/requests/user/ResetPasswordRequest";

import useQuery from "../../../../hooks/useQuery";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/sharp-regular-svg-icons/faArrowLeft";
import {useTranslation} from "react-i18next";
import {useActionTokenValidationMutation} from "../../../../redux/api/users";
import useDebounce from "../../../../hooks/useDebounce";

const ResetPasswordForm = () => {
    const {t} = useTranslation();
    const [mounted, setMounted] = useState(false);
    const [success, setSuccess] = useState(false);
    const [allowReset, setAllowReset] = useState(true);
    const [resetPassword] = useResetPasswordMutation();
    const [validateToken] = useActionTokenValidationMutation();
    const query = useQuery();
    const navigate = useNavigate();
    const debounce = useDebounce(3000);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        values.token = query.get('token') as string;
        try {
            const result = await resetPassword(values as ResetPasswordRequest).unwrap();
            setSuccess(result);

            debounce(() => {
                navigate('/');
            });
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: submit,
    });

    const validateTokenRequest = async (token: string) => {
        try {
            const response = await validateToken({actionToken: token}).unwrap();
            setAllowReset(response);
        } catch (err) {
            setAllowReset(false);
        }
    };

    const linkStyle = {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    };

    useEffect(() => {
        const token = query.get('token') as string;

        if(!token) {
            setAllowReset(false);
        } else {
            validateTokenRequest(token);
        }

        setMounted(true);
    },[]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
            <Helmet>
                <title>{t('helmet:resetPassword')}</title>
            </Helmet>
            {mounted && (
                <Portal container={document.getElementById('pageTitle')} >
                    <PageTitle textPrimary={t('pageTitle:resetPassword')} />
                </Portal>
                )}

                {allowReset ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography mb={2}>
                                {t('form:resetPasswordDescription')}
                            </Typography>

                            {success && (
                                <Alert severity="success" sx={{mb: 3}}>
                                    {t('notification:resetPassword.success')}
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="password"
                                variant="outlined"
                                label={t('form:password')}
                                name="password"
                                type="password"
                                formik={formik}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="confirmPassword"
                                variant="outlined"
                                label={t('form:confirmPassword')}
                                name="confirmPassword"
                                type="password"
                                formik={formik}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            xs={12}
                            spacing={2}
                            justifyContent={"space-between"}
                        >
                            <Grid item>
                                <Link to="/" title={t('button:backToLogin')} style={linkStyle}>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                    {t('button:backToLogin')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained">
                                    {t('button:resetPassword')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography mb={2}>
                                {t('notification:resetPassword:invalidToken')}
                            </Typography>
                            <Grid item mt={4}>
                                <Link to="/forgotpassword" title={t('button:backToResetPassword')} style={linkStyle}>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                    {t('button:backToResetPassword')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </form>
        </>
    );
};

export default ResetPasswordForm;
