import React, {FC, useRef, useState} from 'react';
import {GridColDef} from "@mui/x-data-grid";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {useTranslation} from "react-i18next";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../datagrid/DynamicDataGrid";
import {Helmet} from "react-helmet-async";
import {Button, Divider, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {DateTimeRenderer} from "../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../themes/default";
import {useGetUserCollectionQuery, useUpdateUserStatusMutation, useDeleteUserMutation} from "../../../redux/api/users";
import {UsersFilter} from "./users/UsersFilter";
import {ToggleRenderer} from "../../datagrid/renderers/ToggleRenderer";
import UserResponse from "../../../types/api/responses/user/UserResponse";
import {UserRequest} from "../../../types/api/requests/user/UserRequest";
import {faPen, faTrashCan} from "@fortawesome/sharp-regular-svg-icons";
import {RowActions} from "../../datagrid/RowActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConfirmDialog} from "../../dialogs/ConfirmDialog";
import {UserForm} from "./users/UserForm";
import useSecurity from "../../../hooks/useSecurity";

const Users: FC = () => {
    const hasRole = useSecurity();
    const {t} = useTranslation();
    const [currentFilters, setCurrentFilters] = useState<RequestFilter>([]);
    const [updateUserStatus] = useUpdateUserStatusMutation();
    const [deleteUser] = useDeleteUserMutation();
    const [selectedForEditing, setSelectedForEditing] = useState<UserResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<any|null>(null);
    const [selectedForStatusToggling, setSelectedForStatusToggling] = useState<any|null>(null);
    const [formOpen, setFormOpen] = useState(false);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleCreateUserClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteUser(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }

    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    const handleStatusToggleClick = (rowData: UserResponse, newStatus: boolean) => {
        setSelectedForStatusToggling({...rowData, enabled: newStatus});
    }

    const handleStatusToggleConfirm = async () => {
        if (selectedForStatusToggling) {
            await updateUserStatus({id: selectedForStatusToggling.id, values: { enabled: selectedForStatusToggling.enabled} as unknown as UserRequest }).unwrap();
            setSelectedForStatusToggling(null);
            gridRef.current?.reload();
        }
    }

    const handleStatusToggleDecline = () => {
        setSelectedForStatusToggling(null);
    }

    const columns: GridColDef[] = [
        { field: 'lastName', headerName: t('datagrid:users:name'),editable: false, flex: 1, minWidth: 200, renderCell: (params) => params.row.name },
        { field: 'email', headerName: t('datagrid:users:email'), editable: false, flex: 1, minWidth: 175, renderCell: (params) => params.row.email },
        {
            field: 'enabled',
            headerName: t('datagrid:users:enabled'),
            minWidth: 125,
            renderCell: (params) => (
                <ToggleRenderer
                    value={params.row.enabled}
                    onToggle={(value) => handleStatusToggleClick(params.row, value)}
                    color={params.row.enabled ? 'success' : 'error'}
                    disabled={params.row.roles.length > 1 && params.row.roles[1] === "ROLE_SUPER_ADMIN"}
                />
            ),
        },
        { field: 'createdAt', headerName: t('datagrid:users:createdAt'), editable: false, minWidth: 200, renderCell: (params) => <DateTimeRenderer date={params.row.createdAt} /> },
        { field: 'roles', headerName: t('datagrid:users:role'), editable: false, minWidth: 200, renderCell: (params) => params.row.roles.length > 1 ? t(`datagrid:users:${params.row.roles[1]}`) : t(`datagrid:users:${params.row.roles[0]}`) },
        {
            field: 'actions',
            headerName: t('datagrid:actions'),
            headerAlign: "left",
            width: 125,
            align: "left",
            renderCell: (params) => {
                const actions = [];

                if(params.row.roles.length === 1 || params.row.roles.length > 1 && params.row.roles[1] !== "ROLE_SUPER_ADMIN") {
                    actions.push({
                        icon: <FontAwesomeIcon height={16} width={16} icon={faPen} style={{pointerEvents: 'none'}}/>,
                        label: t('datagrid:edit'),
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    });

                    actions.push({
                        icon: <FontAwesomeIcon height={16} width={16} icon={faTrashCan}
                                               style={{color: theme.palette.secondary.main, pointerEvents: 'none'}}/>,
                        label: t('datagrid:delete'),
                        onClick: async () => {
                            setSelectedForDeleting(params.row);
                        }
                    });
                }

                return <RowActions actions={actions}/>;
            },
        },
    ];

    let filteredColumns: GridColDef<any, any, any>[] = [];

    filteredColumns = columns.map(column => ({ ...column }));

    return (
        <>
            <Helmet>
                <title>{t('helmet:users')}</title>
            </Helmet>
            <Paper
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.primary.light}`
                }}
            >
                <Grid container>
                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                        <Grid item xs={12} sm={6} >
                            <Typography variant="h5" py={0.75}>
                                {t('pageTitle:users')}
                            </Typography>
                        </Grid>
                        {hasRole('ROLE_ADMIN') && (
                            <Grid item xs={12} sm={6}  textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    size={'large'}
                                    sx={{
                                        background: "#01C677",
                                        height: 48,
                                        width: {
                                            xs: 48,
                                            md: "auto"
                                        },
                                        p: {
                                            xs: 0,
                                            md: "15px",
                                        },
                                        minWidth:48
                                    }}
                                    onClick={handleCreateUserClick}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: {
                                                xs: "none",
                                                md: "inline"
                                            }
                                        }}
                                        fontWeight={700}
                                        fontSize={13}
                                    >
                                        {t('button:createUser')}
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </Stack>

                    <Divider sx={{width: "100%"}} />

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                        }}
                        filter={currentFilters}
                        filterComponent={<UsersFilter onFilterChanged={(filters: any) => setCurrentFilters(filters)} />}
                        ref={gridRef}
                        columns={filteredColumns}
                        queryHook={useGetUserCollectionQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Grid>
                <Snackbar />
            </Paper>

            <UserForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
            />

            {selectedForDeleting && (
                <ConfirmDialog
                    cancelLabel={t('button:cancel')}
                    confirmLabel={t('button:delete')}
                    title={t('dialog:deleteUser')}
                    message={t('dialog:confirmDeleteMember', { name: selectedForDeleting.name })}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}

            {selectedForStatusToggling && (
                <ConfirmDialog
                    cancelLabel={t('button:cancel')}
                    confirmLabel={t('button:confirm')}
                    title={t('dialog:toggleUserStatusTitle', {
                        action: selectedForStatusToggling.enabled ? t('dialog:activate') : t('dialog:deactivate')
                    })}
                    message={t('dialog:toggleUserStatusMessage', {
                        name: selectedForStatusToggling.name,
                        action: selectedForStatusToggling.enabled ? t('dialog:activate') : t('dialog:deactivate')
                    })}
                    onYes={handleStatusToggleConfirm}
                    onNo={handleStatusToggleDecline}
                />
            )}

        </>
    );
};

export default Users;