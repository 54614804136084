import React, {forwardRef, ForwardRefRenderFunction, ReactNode, useEffect, useImperativeHandle, useState} from "react";
import {DataGrid, DataGridProps} from "./DataGrid";
import {RequestFilter} from "../../types/api/requests/DynamicListRequest";

export type DynamicDataGridProps = Omit<DataGridProps, "rows"> & {
    filter?: RequestFilter,
    updateInterval?: number,
    queryHook: any,
    filterComponent?: ReactNode,
    extraRequestParams?: object,
};

export type DynamicDataGridHandle = {
    reload: () => void,
}

const DynamicDataGrid: ForwardRefRenderFunction<DynamicDataGridHandle, DynamicDataGridProps> = ({filter, filterComponent, queryHook, extraRequestParams = {}, updateInterval, ...props}, ref) => {
    const [paginationModel, setPaginationModel] = useState(props.paginationModel || {page: 0, pageSize: 25});
    const [sort, setSort] = useState(props.sortModel);
    const {data, isFetching, refetch} = queryHook({
        filter,
        page: (paginationModel.page + 1),
        pageSize: paginationModel.pageSize,
        sort,
        ...extraRequestParams,
    });

    useImperativeHandle(ref, () => ({
        reload() {
            refetch();
        }
    }));

    useEffect(() => {
        if (updateInterval) {
            const interval = setInterval(() => {
                refetch();
            }, updateInterval);

            return () => clearInterval(interval);
        }
    }, [updateInterval, refetch]);

    let rows: Array<object> = [];
    if (!isFetching && data) {
        rows = Object.values(data['hydra:member']);
    }

    return (
        <DataGrid
            loading={isFetching}
            filterComponent={filterComponent}
            paginationMode="server"
            rowHeight={50}
            columnHeaderHeight={50}
            onPaginationModelChange={(model) => setPaginationModel(model)}
            sortingMode="server"
            onSortModelChange={(sort) => setSort(sort)}
            rowCount={data ? data['hydra:totalItems'] : 0}
            rows={rows}
            {...props}
        />
    );
}

export default forwardRef(DynamicDataGrid);
