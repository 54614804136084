import React, {FC} from "react";
import moment from "moment";

type DateTimeRendererProps = {
    date: Date,
}

export const DateTimeRenderer: FC<DateTimeRendererProps> = ({date}) => {
    return <>{moment(date).format('DD-MM-YYYY HH:mm')}</>;
}
