import {store} from "../redux/store";
import {FormikHelpers} from "formik";
import {showSnackbar} from "../redux/slices/layout";
import { t } from 'i18next';
/**
 * Helper class for API Errors
 */
class ApiErrorHelper
{
    /**
     * @param error
     * @param formikHelpers
     */


    public processErrors(error: any, formikHelpers: FormikHelpers<any> | undefined = undefined): void
    {
        switch(error.status) {
            case 'FETCH_ERROR':
            case 'PARSING_ERROR':
            case 'CUSTOM_ERROR':
                store.dispatch(showSnackbar({type: 'error', message: t('notification:errorNetwork')}));
                break;

            case 400:
                store.dispatch(showSnackbar({type: 'error', message:  `Notification: ${t(error.data.message)}`}));
                break;

            case 401:
                store.dispatch(showSnackbar({type: 'error', message:  `Notification: ${t(error.data.message)}`}));
                break;

            case 403:
                store.dispatch(showSnackbar({type: 'error', message: t('notification:errorResource')}));
                break;

            case 422:
                if( formikHelpers ) {
                    this.processValidationErrors(error.data, formikHelpers);
                }
                break;

            default:
                if (error.data && error.data['hydra:description']) {
                    store.dispatch(showSnackbar({type: 'error', message: t(`notification:${error.data['hydra:description']}`)}));
                }
                console.error(error.status);
                console.error(error.data);
                console.error(error);
                break;
        }
    }

    /**
     * @param data
     * @param formikHelpers
     * @private
     */
    private processValidationErrors(data: any, formikHelpers: FormikHelpers<any>): void
    {
        data.violations.forEach((violation: any) => {
            formikHelpers.setFieldError(violation.propertyPath, t(`notification:${violation.message}`));
        });
    }
}

export default new ApiErrorHelper() as ApiErrorHelper;
