import React, {FC, useEffect, useRef} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {useTranslation} from "react-i18next";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import {Box, IconButton, Paper, Stack} from "@mui/material";
import {TextField} from "formik-mui";
import {useLazyGetSalesOrderByOrderIdQuery} from "../../../../redux/api/salesorders";
import {useNavigate} from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Search from '@mui/icons-material/Search';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode} from "@fortawesome/pro-solid-svg-icons";
import useSendPrintCommand from "../../../../hooks/useSendPrintCommand";
import {useGetMeQuery} from "../../../../redux/api/users";

export type SalesOrderBarcodeFormProps = {
    style?: any
};

export const SalesOrderBarcodeForm: FC<SalesOrderBarcodeFormProps> = ({style}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [getSalesOrder] = useLazyGetSalesOrderByOrderIdQuery();
    const inputRef = useRef<any>(null);
    // Disabled for now, client does not want to enable automatic printing of labels after scanning barcode
    // const sendPrintCommand = useSendPrintCommand();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            values.orderId = parseInt(values.orderId);

            const salesOrderId = await getSalesOrder({id: values.orderId}).unwrap();
            navigate(`/application/salesorders/details/${salesOrderId.id}`, { state: { id: salesOrderId.id} });

            // Disabled for now, client does not want to enable automatic printing of labels after scanning barcode
            // await sendPrintCommand(salesOrderId.id);

            formikHelpers.resetForm();

        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    }

    useEffect(() => {
        if (!window.location.href.includes('application/labels/edit/')) {
            inputRef.current.focus();

            document.body.addEventListener('keypress', (e) => {
                if (e.target instanceof HTMLInputElement) {
                    return;
                }

                inputRef.current.focus();
            });
        }
    });

    return (
        <Paper
            color="primary"
            sx={ style }
        >
            <Box
                sx={{
                    width: "max-content",
                    margin: "0 auto"
                }}
            >
                <Box>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            orderId: '',
                        }}
                        onSubmit={submit}
                    >
                        {({ values, submitForm, isSubmitting, resetForm }) => (
                            <Form autoComplete="off">
                                <Stack
                                    direction={"row"}
                                    spacing={0}
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        width: "max-content"
                                    }}
                                >

                                    <Field
                                        component={TextField}
                                        name="orderId"
                                        type="text"
                                        size="small"
                                        placeholder={t('form:orderNumberPlaceholder')}
                                        fullWidth={false}
                                        autoFocus={true}
                                        InputProps={{
                                            style: {
                                                minWidth: "650px",
                                                borderRadius: "0px"
                                            },
                                            startAdornment: <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faBarcode} />
                                            </InputAdornment>,
                                        }}
                                        inputRef={ inputRef }
                                    />
                                    <IconButton
                                        aria-label={t('form:togglePasswordVisibility')}
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                        edge="end"
                                        sx={{
                                            borderRadius: 0,
                                            backgroundColor: "#ff0000",
                                            ":hover": {
                                                backgroundColor: "#000000"
                                            }
                                        }}
                                    >
                                        <Search sx={{ color: "#FFFFFF" }} />
                                    </IconButton>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Paper>
    );
}