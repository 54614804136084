import React, { FC, useState } from "react";
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Typography,
    Grid, Backdrop
} from "@mui/material";
import {FormikHelpers, useFormik} from "formik";
import {AutocompleteMultipleWithNewField} from "../../../form/AutocompleteMultipleWithNewField";
import {
    useCreateSkuMutation,
    useGetSkusWithoutLabelsCollectionQuery
} from "../../../../redux/api/skus";
import { Box } from "@mui/system";
import { useCreateSourceMutation } from "../../../../redux/api/source";
import { useNavigate } from "react-router-dom";
import { SourceRequest } from "../../../../types/api/requests/source/SourceRequest";
import {useTranslation} from "react-i18next";
import {UploadFileOutlined} from "@mui/icons-material";
import {DropzoneArea} from "mui-file-dropzone";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";

interface CreateLabelFormProps {
    title?: string;
}

export const CreateLabelForm: FC<CreateLabelFormProps> = ({}) => {
    const {t} = useTranslation();
    const [createSku] = useCreateSkuMutation();
    const navigate = useNavigate();
    const [createSource] = useCreateSourceMutation();
    const { data: skus, isLoading: isLoading } = useGetSkusWithoutLabelsCollectionQuery({ page: 1, pageSize: 999999999 });
    const [isGenerating, setIsGenerating] = useState(false);

    const validate = (values: SourceRequest) => {
        const errors: { skusa8?: string; skusa6?: string; fileUpload?: string } = {};


        if (values.skusa8.length === 0 && values.skusa6.length === 0) {
            errors.skusa8 = t('errors:noskusA8');
            errors.skusa6 = t('errors:noSkusA6');
        }

        if (!values.fileUpload || 0 === values.fileUpload.length) {
            errors.fileUpload = t('errors:noFileUploaded');
        }

        return errors;
    };

    const formik = useFormik<SourceRequest>({
        initialValues: {
            skusa8: '',
            skusa6: '',
            fileUpload: ''
        },
        validate,
        onSubmit: async (values, formikHelpers: FormikHelpers<any>) => {
            try {
                setIsGenerating(true);

                const formData = new FormData();
                formData.append('skusa6', JSON.stringify(values.skusa6));
                formData.append('skusa8', JSON.stringify(values.skusa8));
                formData.append('fileUpload', values.fileUpload[0]);

                const source = await createSource(formData).unwrap();

                navigate(`/application/labels/edit/${source.id}`);
                formik.resetForm();
            } catch (e) {
                ApiErrorHelper.processErrors(e, formikHelpers);
                formikHelpers.setSubmitting(false);
            }
            setIsGenerating(false);
        }
    });

    if (isLoading || skus === undefined) {
        return <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>;
    }

    return (
        <>
            <Container sx={{minWidth: "700px"}}>
                <Divider />
                <Box sx={{ marginBottom: 2, padding: "20px 0" }} display="flex" flexDirection="column" gap="25px">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant={"h5"}>A8: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <AutocompleteMultipleWithNewField
                                name="skusa8"
                                formik={formik}
                                label={t('labels:addSkuPlaceholder')}
                                sx={{width: "500px", padding: '0'}}
                                options={skus['hydra:member'].map(sku => ({value: sku.id, label: sku.sku}))}
                                onNewEntry={ async (newValue) => {
                                    const newSku = await createSku({sku: newValue}).unwrap();
                                    return {value: newSku.id, label: newSku.sku};
                                }}
                            />
                            {formik.errors.skusa8 && formik.touched.skusa8 ? (
                                <div style={{ color: 'red' }}>
                                    {Array.isArray(formik.errors.skusa8)
                                        ? formik.errors.skusa8.join(', ')
                                        : formik.errors.skusa8}
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant={"h5"}>A6: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <AutocompleteMultipleWithNewField
                                name="skusa6"
                                formik={formik}
                                label={t('labels:addSkuPlaceholder')}
                                sx={{width: "500px", padding: '0'}}
                                options={skus['hydra:member'].map(sku => ({value: sku.id, label: sku.sku}))}
                                onNewEntry={async (value) => {
                                    const newSku = await createSku({sku: value}).unwrap();

                                    return {value: newSku.id, label: newSku.sku};
                                }}
                            />
                            {formik.errors.skusa6 && formik.touched.skusa6 ? (
                                <div style={{ color: 'red' }}>
                                    {Array.isArray(formik.errors.skusa6)
                                        ? formik.errors.skusa6.join(', ')
                                        : formik.errors.skusa6}
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Box>
                <DropzoneArea
                    fileObjects={[]}
                    filesLimit={1}
                    Icon={UploadFileOutlined}
                    acceptedFiles={['image/*']}
                    dropzoneText={t('labels:upload')}
                    onChange={(e) => {
                        formik.setFieldValue('fileUpload', e);
                    }}
                    onDelete={(e) => {
                        formik.setFieldValue('fileUpload', []);
                    }}
                />
                {formik.errors.fileUpload && formik.touched.fileUpload ? (
                    <div style={{ color: 'red' }}>{formik.errors.fileUpload}</div>
                ) : null}
                <Box sx={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                    <Button
                        sx={{
                            background: "#01C677",
                            borderRadius: 0,
                            color: 'white',
                            textTransform: 'none',
                            padding: "8px 15px"
                        }}
                        onClick={formik.submitForm}
                        variant="contained"
                        color="primary"
                        disabled={
                            0 === formik.values.fileUpload.length ||
                            0 === formik.values.skusa6.length &&
                            0 === formik.values.skusa8.length
                        }
                    >
                        {t('labels:generateLabel')}
                    </Button>
                </Box>
            </Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGenerating}
            >
                <Typography sx={{position: "absolute", top: "53%"}}>{t('labels:generatingLabel')}</Typography>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
