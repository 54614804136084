import React, {FC} from 'react';
import {
    Link,
    Stack,Typography
} from "@mui/material";
import theme from "../../themes/default";
import {HtmlRenderer} from "../datagrid/renderers/HtmlRenderer";

export type ResponsiveDataRowProps = {
    key: string|number,
    label: string,
    value: any,
    labelMinWidth?: number,
    valueMinWidth?: number,
    parseHtml?: boolean,
    isLink?: boolean,
}

const ResponsiveDataRow: FC<ResponsiveDataRowProps> = ({label, value, labelMinWidth, valueMinWidth, parseHtml,  isLink }) => {

    return (
        <>
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row'
                }}
                sx={{
                    my: 1.5
                }}
            >
                <Typography component={"span"} minWidth={labelMinWidth}>
                    {label}
                </Typography>
                {isLink ? (
                    <Link href={value} target="_blank" rel="noopener" minWidth={valueMinWidth} fontWeight={500} sx={{color: theme.palette.text.primary }}>
                        {value}
                    </Link>
                ) : (
                    <Typography component="span" minWidth={valueMinWidth} width="60%" fontWeight={500}>
                        { parseHtml ? <HtmlRenderer html={value} /> : value }
                    </Typography>
                )}
            </Stack>
        </>
    );
};

export default ResponsiveDataRow;
