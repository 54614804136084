import * as React from "react";
import {FC, PropsWithChildren} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";

export type AppDialogProps = DialogProps & PropsWithChildren & {
    title: string;
    subTitle?: string;
    subText?: string;
    onClose: () => void;
}

export const AppDialog: FC<AppDialogProps> = ({open, title, subTitle, subText, onClose, children, ...restProps}) => {
    return (
        <Dialog open={open} onClose={onClose} TransitionComponent={Transition} {...restProps}>
            <DialogTitle component={Toolbar}>
                <Stack direction={"column"} alignItems={"center"} justifyContent={"center "} flexGrow={1}>
                    <Typography variant="h6" component="span" sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
                        {title}
                    </Typography>
                    {subTitle &&
                        <Box fontSize={30} component="span">
                            {subTitle}
                        </Box>
                    }
                    {subText &&
                        <Box fontSize={14} component="span" fontWeight={'normal'} pt={3}>
                            {subText}
                        </Box>
                    }
                </Stack>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{
                        alignSelf: "flex-start",
                        position: "absolute",
                        right: '23px',
                        top: '15px',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                { children }
            </DialogContent>
        </Dialog>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
