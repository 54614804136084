import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

export type FilterValue = {
    [key: string]: string,
}

export interface DataGridState {
    selected: Array<string | number>,
    showFilters: boolean,
    filters: {[key: string]: FilterValue} // Key = datagrid name, value = array of filters
}

const initialState: DataGridState = {
    selected: [],
    showFilters: false,
    filters: {},
};

export const dataGridSlice = createSlice({
    name: 'dataGrid',
    initialState,
    reducers: {
        setDataGridSelection: (state: Draft<DataGridState>, action: PayloadAction<Array<string | number>>) => {
            state.selected = action.payload;
        },

        toggleShowFilters: (state: Draft<DataGridState>) => {
            state.showFilters = !state.showFilters;
        },

        setFilters: (state: Draft<DataGridState>, action: PayloadAction<{name: string, filters: FilterValue}>) => {
            state.filters[action.payload.name] = action.payload.filters;
        },

        clearFilters: (state: Draft<DataGridState>, action: PayloadAction<string>) => {
            state.filters[action.payload] = {};
        },

        clearAllFilters: (state: Draft<DataGridState>) => {
            state.filters = {};
        }
    },
});

export const {
    setDataGridSelection,
    toggleShowFilters,
    setFilters,
    clearFilters,
    clearAllFilters,
} = dataGridSlice.actions;

export default dataGridSlice.reducer;
