import React, {useEffect, useState} from 'react';
import {Button, Portal, Stack, LinearProgress, IconButton, Alert, Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import theme from "../../../../themes/default";
import PageTitle from "../../../../components/PageTitle";
import {Helmet} from "react-helmet-async";
import {Formik, Form, Field} from 'formik';
import {useNavigate} from "react-router-dom";
import {useLoginMutation} from "../../../../redux/api/auth";
import {useTranslation} from 'react-i18next';
import {TextField} from 'formik-mui';
import {FormikHelpers} from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Values {
    email: string;
    password: string;
}

const LoginForm = () => {

    const {t} = useTranslation();
    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const [login] = useLoginMutation();
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await login(values).unwrap();
            navigate('/application/scanorder');
        } catch (err: any) {
            //ApiErrorHelper.processErrors(err, formikHelpers);
            setError(err.data.message)
            formikHelpers.setSubmitting(false);
        }
    };

    const linkStyle = {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
        },
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    return (

        <>
            <Helmet>
                <title>{t('helmet:login')}</title>
            </Helmet>
            {mounted && (
                <Portal container={document.getElementById('pageTitle')} >
                    <Typography variant={"h4"}>
                        {t('pageTitle:login')}
                    </Typography>
                </Portal>
            )}
            {error && (
                <Alert variant="filled" severity="error" sx={{mb:3}}>
                    {t('notification:invalidCredentials')}
                </Alert>
            )}
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validate={(values) => {
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email = t('form:required');
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = t('notification:invalidEmail');
                    }
                    return errors;
                }}
                onSubmit={submit}
            >
                {({ submitForm, isSubmitting }) => (

                    <Form onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            submitForm();
                        }
                    }}>
                        <Stack spacing={2}>
                                <Field
                                    component={TextField}
                                    name="email"
                                    type="email"
                                    label={t('form:email')}
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}
                                />
                                <Field
                                    component={TextField}
                                    type={showPassword ? 'text' : 'password'}
                                    label={t('form:password')}
                                    name="password"
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label={t('form:togglePasswordVisibility')}
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            <Box height={4}>
                                {isSubmitting && <LinearProgress />}
                            </Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                {t('button:login')}
                            </Button>
                            <Link to="/forgotpassword" title={t('form:forgotPassword')} style={linkStyle}>
                                {t('form:forgotPassword')}
                            </Link>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default LoginForm;
