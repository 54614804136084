import {FC} from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import {RequestFilter} from "../../../../types/api/requests/DynamicListRequest";
import {useAppSelector} from "../../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../../hooks/useAppDispatch";
import {setFilters} from "../../../../redux/slices/datagrid";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useTranslation} from "react-i18next";
import moment, {Moment} from "moment";

export type SalesOrdersFilterProps = {
    onFilterChanged: (filter: RequestFilter) => void,
}

let timeout: ReturnType<typeof setTimeout>;
export const SalesOrdersFilter: FC<SalesOrdersFilterProps> = ({onFilterChanged}) => {
    const filterValues = useAppSelector(state => state.datagrid.filters.salesorders || {});
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const setFilter = (name: string, value: string) => {
        const newFilter = {...filterValues, [name]: value}
        dispatch(setFilters({name: 'salesorders', filters: newFilter}));

        if(timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            const filters: RequestFilter = [];
            Object.entries(newFilter).forEach((value) => {
                filters.push({field: value[0], value: value[1]});
            });

            onFilterChanged(filters);
            clearTimeout(timeout);
        }, 500);
    }

    return (
        <>
            <Stack
                sx={{
                    gap: 2,
                    [theme.breakpoints.between('lg', 'xl')]: {
                        gap: 1
                    },
                    flexWrap: 'wrap',
                    marginTop: "-20px",
                    marginBottom: "-20px"
                }}
                direction={{
                    xs: "column",
                    lg: "row",
                }}
            >
                <TextField
                    sx={{flex:1}}
                    size="small"
                    label={t('form:orderId')}
                    variant="outlined"
                    name="filter-orderid"
                    className={'dense'}
                    value={filterValues['orderId'] || ''}
                    onChange={(e) => setFilter('orderId', e.target.value)}
                />
                <FormControl variant="outlined" sx={{flex:1}} className={'dense'}>
                    <InputLabel id="filter-status-label">
                        {t('form:status')}
                    </InputLabel>
                    <Select
                        size={"small"}
                        labelId="filter-status-label"
                        id="filter-status"
                        value={filterValues['status'] || ''}
                        onChange={(e) => setFilter('status', e.target.value)}
                        label={t('form:status')}
                    >
                        <MenuItem value={''}>{t('form:all')}</MenuItem>
                        <MenuItem value={'ORDER_OPEN'}>{t('form:open')}</MenuItem>
                        <MenuItem value={'ORDER_PROCESSED'}>{t('form:processed')}</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    size="small"
                    sx={{flex:1}}
                    label={t('form:countryCode')}
                    variant="outlined"
                    name="filter-countrycode"
                    className={'dense'}
                    value={filterValues['countryCode'] || ''}
                    onChange={(e) => setFilter('countryCode', e.target.value)}
                />
                <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    label={t('form:createdAfter')}
                    value={filterValues['createdAt[after]'] ? moment(filterValues['createdAt[after]']) : null }
                    onChange={(newValue) => newValue && setFilter('createdAt[after]', (newValue as unknown as Moment).format('YYYY-MM-DD'))}
                />
            </Stack>
        </>
    );
}