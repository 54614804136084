import React, {FC} from 'react';
import {Field} from "formik";
import {SettingsCard} from "./SettingsCard";
import {TextField} from "formik-mui";
import {useTranslation} from "react-i18next";
export const GeneralForm: FC = () => {
    const [t] = useTranslation();

    return (
        <>
            <SettingsCard title={t('settings:emailSettings')}>
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:senderName')}
                    name="general/email/from_name"
                    InputLabelProps={{ shrink: true }}
                    required
                />
                <Field
                    fullWidth
                    component={TextField}
                    type="text"
                    label={t('settings:senderEmail')}
                    name="general/email/from_address"
                    InputLabelProps={{ shrink: true }}
                    required
                />
            </SettingsCard>
        </>
    );
}
