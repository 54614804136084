import React from 'react';
import Typography from '@mui/material/Typography';
import theme from "../themes/default";

type PageTitleProps = {
    textPrimary: string;
    textSecondary?: string;
};

const styles = {
    fontSize: {
        xs: 36,
        xl: 50,
    },
    fontWeight: "800",
    lineHeight: "1.1",
}

const PageTitle = ({textPrimary, textSecondary}: PageTitleProps) => {
    return (
        <Typography
            variant="h1"
            whiteSpace={"pre-line"}
            sx={styles}
        >
            {textPrimary}
            <Typography
                component="span"
                variant="h1"
                sx={styles}
                color={theme.palette.primary.main}
                fontStyle={"italic"}
            >
                {textSecondary}
            </Typography>
        </Typography>
    );
}

export default PageTitle;