import React, {FC} from "react";
import {styled, Switch} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";

type ToggleRendererProps = {
    color?: 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning',
    onToggle?: (value: boolean) => void,
    value: boolean,
    disabled?: boolean,
}

export const ToggleRenderer: FC<ToggleRendererProps> = ({color, onToggle, value, disabled}) => {
    return <StyledToggle
        checked={value}
        color={color || 'primary'}
        onChange={(e, checked) => onToggle && onToggle(checked)}
        disabled={disabled}
    />;
}

const StyledToggle = styled(Switch)(({ color, theme }) => {
    return {
        '& .MuiSwitch-track': {
             backgroundColor: ArrayHelper.get(theme.palette, color+'.main'),
        },

        '& .MuiSwitch-thumb': {
            color: '#ffffff'
        },
    }
});
