import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import SalesOrderResponse from "../../types/api/responses/salesorder/SalesOrderResponse";

const salesordersApi = api.injectEndpoints({
    endpoints: build => ({
        getSalesOrderCollection: build.query<SalesOrderResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('/api/sales_orders', request),
        }),

        getSalesOrder: build.query<SalesOrderResponse, {id: number}>({
            query: (salesOrder) => ({
                url: `/api/sales_orders/${salesOrder.id}`,
                method: 'GET',
            }),
            providesTags: ['salesOrder'],
        }),

        getSalesOrderByOrderId: build.query<SalesOrderResponse, {id: number}>({
            query: (salesOrder) => ({
                url: `/api/sales_orders/${salesOrder.id}/by_order_id`,
                method: 'GET',
            })
        }),

        // getLabelsToPrint: build.query<{data: string}, {id: number}>({
        //     query: (salesOrder) => ({
        //         url: `/api/sales_orders/${salesOrder.id}/get-labels-to-print`,
        //         method: 'GET',
        //     })
        // }),

        getLabelsToPrint: build.query<any, {id: number} & DynamicListRequest>({
            query: (salesOrder) => UrlHelper.buildUrl(`/api/sales_orders/${salesOrder.id}/get-labels-to-print`, salesOrder),
        }),

        updatePrintLabelStatus: build.mutation<SalesOrderResponse, {id: number, values: any}>({
            query: (salesOrder) => ({
                url: `/api/sales_orders/${salesOrder.id}/update-label-status`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...salesOrder.values}),
            }),
            invalidatesTags: ['salesOrder'],
        }),
    }),
});

export const {
    useGetSalesOrderCollectionQuery,
    useGetSalesOrderQuery,
    useLazyGetSalesOrderByOrderIdQuery,
    useLazyGetLabelsToPrintQuery,
    useUpdatePrintLabelStatusMutation,
} = salesordersApi;
export default salesordersApi;