import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import api from './api/api';
import { auth } from './slices/auth';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import dataGridReducer from './slices/datagrid';
import layoutReducer from './slices/layout';
import labelSlice from "./slices/label";

const authPersistConfig = {
    key: 'auth',
    storage,
}

const persistedAuthReducer = persistReducer(authPersistConfig, auth.reducer);

// Define all reducers
const reducer = {
    [api.reducerPath]: api.reducer,
    auth: persistedAuthReducer,
    datagrid: dataGridReducer,
    layout: layoutReducer,
    label: labelSlice.reducer
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

