import React, {FC} from "react";
import {
    Button,
    LinearProgress,
    Stack,
    Typography,
    Box, MenuItem, FormControl, InputLabel, Select,
} from '@mui/material';
import {FormikHelpers} from "formik";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import { Formik, Form, Field } from 'formik';
import {TextField} from 'formik-mui';
import {useCreateUserMutation, useUpdateUserMutation} from "../../../../redux/api/users";
import NotificationHelper from "../../../../helpers/NotificationHelper";
import {AppDialog} from "../../../dialogs/AppDialog";
import {useTranslation} from 'react-i18next';
import UserResponse from "../../../../types/api/responses/user/UserResponse";
import usePrintLocation from "../../../../hooks/usePrintLocation";

export type UserFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: UserResponse | null,
}

interface Values {
    firstName:string;
    infix: string;
    lastName: string;
    email: string;
    role: string;
    location_a8: string;
    location_a6: string;
}

export const UserForm: FC<UserFormProps> = ({action, initialValues, open, close}) => {
    const {t} = useTranslation();
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const printLocation = usePrintLocation();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            values.printLocations = [
                {dimension: 'a8', value: values.location_a8},
                {dimension: 'a6', value: values.location_a6},
            ];

            delete values.location_a8;
            delete values.location_a6;

            if (action === 'create') {
                await createUser(values).unwrap();
                NotificationHelper.showSuccess(t('notification:successAddMember'));
            } else {
                await updateUser({id: values.id, values}).unwrap();
                NotificationHelper.showSuccess(t('notification:successEditMember'));
            }
            close();
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const formInitialValues = {
        id: initialValues?.id || null,
        firstName: initialValues?.firstName || '',
        infix: initialValues?.infix || '',
        lastName: initialValues?.lastName || '',
        email: initialValues?.email || '',
        location_a8: initialValues && printLocation(initialValues, 'a8') || '',
        location_a6: initialValues && printLocation(initialValues, 'a6') || '',
        role: initialValues && initialValues.roles && initialValues.roles.length > 1
            ? initialValues.roles[1]
            : initialValues?.roles[0] || 'ROLE_USER',
    };

    return (
        <AppDialog
            open={open}
            onClose={close}
            title={t(`${action === 'create' ? 'dialog:addMemberTitle' : 'dialog:editMemberTitle'}`)}
        >
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validate={(values) => {
                    const errors: Partial<Values> = {};
                    if (!values.firstName) {errors.firstName = t('form:required');}
                    if (!values.lastName) {errors.lastName = t('form:required');}
                    if (!values.email) {
                        errors.email = t('form:required');
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = t('form:invalidEmail');
                    }

                    return errors;
                }}
                onSubmit={submit}
            >
                {({ values, submitForm, isSubmitting, touched, setFieldValue, errors }) => (
                    <Form>
                        <Stack direction={"column"} spacing={3} sx={{ pt: 1 }}>
                            <Stack
                                direction={{
                                    xs:"column",
                                    lg:"row"
                                }}
                                spacing={3}
                            >
                                <Field
                                    component={TextField}
                                    name="firstName"
                                    value={values.firstName}
                                    type="text"
                                    label={t('form:firstName')}
                                    fullWidth={true}
                                    required
                                />
                                <Field
                                    component={TextField}
                                    name="infix"
                                    value={values.infix}
                                    type="text"
                                    label={t('form:infix')}
                                    fullWidth={true}
                                />
                                <Field
                                    component={TextField}
                                    name="lastName"
                                    value={values.lastName}
                                    type="text"
                                    label={t('form:lastName')}
                                    fullWidth={true}
                                    required
                                />
                            </Stack>

                            <Field
                                component={TextField}
                                name="email"
                                value={values.email}
                                type="email"
                                label={t('form:email')}
                                required
                            />

                            <FormControl>
                                <InputLabel>{t('form:role')}</InputLabel>
                                <Select
                                    name="role"
                                    value={values.role}
                                    onChange={e => {
                                        setFieldValue('role', e.target.value);
                                    }}
                                    variant="outlined"
                                >
                                    <MenuItem value="ROLE_USER">{t('form:roleUser')}</MenuItem>
                                    <MenuItem value="ROLE_ADMIN">{t('form:roleAdmin')}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ gap: '28px' }}>
                                <Field
                                    component={TextField}
                                    name="location_a8"
                                    value={values.location_a8}
                                    type="text"
                                    label={t('form:locationA8')}
                                    placeholder={t('form:locationPlaceholder')}
                                    fullWidth={true}
                                    required
                                />
                                <Field
                                    component={TextField}
                                    name="location_a6"
                                    value={values.location_a6}
                                    type="text"
                                    label={t('form:locationA6')}
                                    placeholder={t('form:locationPlaceholder')}
                                    fullWidth={true}
                                    required
                                />
                            </FormControl>

                            <Box height={4}>
                                {isSubmitting && <LinearProgress />}
                            </Box>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                <Typography fontSize={13} component={"span"}>
                                    {t('form:requiredLabel')}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    {t('button:save')}
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </AppDialog>
    );
}
