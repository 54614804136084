import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import LabelResponse from "../../types/api/responses/label/LabelResponse";
import {LabelsResponse} from "../../types/api/responses/label/LabelsResponse";
import {LabelRequest} from "../../types/api/requests/label/LabelRequest";

const labelsApi = api.injectEndpoints({
    endpoints: build => ({
        getLabelsCollection: build.query<LabelsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('/api/labels/', request),
            providesTags: ['labels'],
        }),

        getLabelsBySkuIdCollection: build.query<LabelsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl(`/api/labels`, request),
            providesTags: ['labelList']
        }),

        getLabelsBySourceIdCollection: build.query<LabelsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl(`/api/labels`, request),
            providesTags: ['labelList']
        }),

        getLabel: build.query<LabelResponse, {id: number}>({
            query: (label) => ({
                url: `/api/labels/${label.id}`,
                method: 'GET',
            })
        }),

        updateLabel: build.mutation<LabelResponse, {id: number, values: LabelRequest}>({
            query: (label) => ({
                url: `/api/labels/${label.id}`,
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify({...label.values}),
            }),
            invalidatesTags: ['skuList', 'labelList','labels']
        }),

        deleteLabel: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/labels/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['labelList'],
        }),

        getExportDownload: build.query<{url: string}, any>({
            query: (request) => ({
                url: '/api/exports/download',
                method: 'POST',
            }),
        }),

        getLabelPDF: build.query<any, {id: number, dimension: string} & DynamicListRequest>({
            query: (label) => ({
                url: UrlHelper.buildUrl(`/api/labels/${label.id}/generate-pdf/${label.dimension}`, label),
                responseHandler: (response) => response.blob(),
            })
        }),
    }),
});

export const {
    useGetLabelsCollectionQuery,
    useGetLabelsBySkuIdCollectionQuery,
    useGetLabelsBySourceIdCollectionQuery,
    useGetLabelQuery,
    useUpdateLabelMutation,
    useDeleteLabelMutation,
    useLazyGetExportDownloadQuery,
    useLazyGetLabelPDFQuery,
} = labelsApi;
export default labelsApi;