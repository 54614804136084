import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import authApi from "../api/auth";

// export type Breadcrumb = {
//     to: string;
//     title: string;
// }

export type Snackbar = {
    message: string;
    type: "error" | "warning" | "info" | "success" | undefined;
}

export interface LayoutState {
   // breadcrumbs: Breadcrumb[];
    snackbar: Snackbar | undefined;
}

const initialState: LayoutState = {
    //breadcrumbs: [],
    snackbar: undefined,
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        // replaceBreadcrumbs: (state: Draft<LayoutState>, action: PayloadAction<Breadcrumb[]>) => {
        //     state.breadcrumbs = initialState.breadcrumbs.concat(action.payload);
        // },
        //
        // clearBreadcrumbs: (state: Draft<LayoutState>) => {
        //     state.breadcrumbs = initialState.breadcrumbs;
        // },

        showSnackbar: (state: Draft<LayoutState>, action: PayloadAction<Snackbar>) => {
            state.snackbar = action.payload;
        },

        clearSnackbar: (state: Draft<LayoutState>) => {
            state.snackbar = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state: Draft<LayoutState>) => {
                state = initialState;
            }
        );
    }
});

export const { showSnackbar, clearSnackbar } = layoutSlice.actions;

export default layoutSlice.reducer;
