import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {SourcesResponse} from "../../types/api/responses/source/SourcesResponse";
import {SourceResponse} from "../../types/api/responses/source/SourceResponse";

const sourceApi = api.injectEndpoints({
    endpoints: build => ({
        getSourcesCollection: build.query<SourcesResponse, DynamicListRequest>({
            query: (request) =>  UrlHelper.buildUrl('/api/sources/', request),
            providesTags: ['sourcesList'],
        }),

        getSourcesByLabelIdCollection: build.query<SourcesResponse, {id: number} & DynamicListRequest>({
            query: (request) => ({
                url: UrlHelper.buildUrl(`/api/sources?labels.id=${request.id}`, request),
            }),
        }),

        getSource: build.query<SourceResponse, {id: number}>({
            query: (source) => ({
                url: `/api/sources/${source.id}`,
                method: 'GET',
            }),
            providesTags: ['source'],
        }),

        createSource: build.mutation<SourceResponse, FormData>({
            query: (source) => ({
                url: `/api/sources`,
                method: 'POST',
                body: source,
            }),
            invalidatesTags: ['sourcesList'],
        }),

        saveSource: build.mutation<SourceResponse, {id: number, sourceData: any}>({
            query: (source) => ({
                url: `/api/sources/${source.id}`,
                method: 'PATCH',
                body: source.sourceData,
            }),
            invalidatesTags: ['sourcesList', 'source'],
        }),

        deleteSource: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/sources/${id}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const {
    useGetSourcesCollectionQuery,
    useGetSourcesByLabelIdCollectionQuery,
    useGetSourceQuery,
    useCreateSourceMutation,
    useSaveSourceMutation,
    useDeleteSourceMutation,
} = sourceApi;
export default sourceApi;
