import React from "react";
import {
    Box, Grid, Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

const Default = () => {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('helmet:scanOrder')}</title>
            </Helmet>
            <Grid container sx={{ margin: "auto 0" }}>
                <Grid
                    container
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow={1}
                    item
                    position="relative"
                    flexWrap={{
                        xs: "nowrap",
                        md: 'wrap'
                    }}
                >
                    <Stack
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box
                            alt={t('auth:tagline')}
                            component="img"
                            display="block"
                            height="100%"
                            src={process.env.REACT_APP_PUBLIC_URL + '/img/logo_grey.svg'}
                            width="200px"
                        />
                        <Typography
                            variant="h1"
                            whiteSpace="pre-line"
                            sx={styles.header}
                        >
                            {/*Scan barcode*/}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default Default;

const styles = {
    header: {
        fontSize: {
            xs: 36,
            xl: 40,
        },
        fontWeight:800,
        lineHeight: "1.1",
        textTransform: "uppercase",
        mt: 4
    },
    barcode: {
        fontSize: {
            xs: 12,
            xl: 16,
        },
        fontWeight:600,
        lineHeight: "1.1",
        textTransform: "uppercase",
        mt: 4
    },
}
