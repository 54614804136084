import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import SalesOrderLineResponse from "../../types/api/responses/salesorderline/SalesOrderLineResponse";

const salesorderlinesApi = api.injectEndpoints({
    endpoints: build => ({
        getSalesOrderLineCollection: build.query<SalesOrderLineResponse, {id: number} & DynamicListRequest>({
            query: (request) => ({
                url: UrlHelper.buildUrl(`/api/sales_order_lines/${request.id}`, request),
            }),
        }),

        getSalesOrderLineByOrderIdCollection: build.query<SalesOrderLineResponse, {id: number} & DynamicListRequest>({
            query: (request) => ({
                url: UrlHelper.buildUrl(`/api/sales_order_lines/${request.id}/by_order_id`, request),
            }),
        }),
    }),
});

export const {
    useGetSalesOrderLineCollectionQuery,
    useGetSalesOrderLineByOrderIdCollectionQuery,
} = salesorderlinesApi;
export default salesorderlinesApi;