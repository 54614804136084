import {Avatar, Box, Button, IconButton, Drawer, ListItemText, Typography, Divider, MenuList, MenuItem, ListItemIcon} from "@mui/material";
import {faXmark} from "@fortawesome/sharp-regular-svg-icons/faXmark";
import {faPowerOff} from "@fortawesome/sharp-regular-svg-icons/faPowerOff";

import {Link} from "react-router-dom";

import theme from "../../themes/default";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Image from "../Image";
import Navigation from "./Navigation";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {logout} from "../../redux/slices/auth";
import {useTranslation} from 'react-i18next';
import api from "../../redux/api/api";

type MobileNavProps = {
    open: boolean;
    onClose: () => void;
    fullName: string;
};

const MobileNav = ({ open, onClose, fullName }: MobileNavProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <Drawer
            anchor="top"
            onClose={onClose}
            open={open}
            sx={{
                width: '100%',
                height: '100%',
                color: "#FFF",
                display: {
                    0: "flex",
                    md: "none"
                },

                '& .MuiDrawer-paper': {
                    boxShadow: "none",
                    borderRadius: 0,
                    px: 2,
                    width: '100%',
                    height: '100%',
                    backgroundColor: theme.palette.secondary.main,
                },
            }}
        >
            <Box
                alignItems={"flex-start"}
                display={"flex"}
                justifyContent={"space-between"}
                py={1}
            >
                <Box>
                    <Button
                        component={Link}
                        onClick={onClose}
                        to="profile"
                        sx={{
                            color: theme.palette.text.primary,
                            textTransform: "capitalize",
                            fontSize: 16,
                            px: 2,
                            py: 1,
                            m: 0,
                        }}
                    >
                        <Avatar
                            alt={fullName}
                            sx={{
                                bgcolor: "#FFF",
                                color:theme.palette.secondary.main,
                                width: 60,
                                height: 60,
                                mr:2,
                            }}
                        />
                        <Box>
                            <Typography fontSize={15} sx={{color: "#FFF", fontWeight: 700 }}>{fullName}</Typography>
                        </Box>
                    </Button>
                </Box>
                <IconButton
                    aria-label="menu"
                    color="inherit"
                    edge="start"
                    onClick={onClose}
                    sx={{
                        display: {
                            0: "block",
                            md: "none"
                        },
                        mr: 0,
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} color={"#FFF"} />
                </IconButton>
            </Box>
            <Navigation onClose={onClose} />
            <Divider
                variant="middle"
                sx={{
                    borderColor: theme.palette.secondary.light,
                    opacity: 0.5,
                    pt: 2,
                }}
            />
            <Box sx={{ width: "100%", maxWidth: '100%' }}>
                <MenuList>
                    {/*<MenuItem onClick={onClose} component={Link} to="/admin/settings">*/}
                    {/*    <ListItemIcon sx={{color:"#FFF"}}>*/}
                    {/*        <FontAwesomeIcon icon={faSliders} />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText disableTypography sx={{color: "#FFF", fontWeight: 700, fontSize:15}}>*/}
                    {/*        {t('settings', { ns: 'navigation' })}*/}
                    {/*    </ListItemText>*/}
                    {/*</MenuItem>*/}
                    <MenuItem>
                        <ListItemIcon sx={{color:"#FFF"}}>
                            <FontAwesomeIcon icon={faPowerOff} />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            sx={{color: "#FFF", fontWeight: 700, fontSize:15}}
                            onClick={() => {
                                dispatch(api.util?.resetApiState());
                                dispatch(logout());
                            }}
                        >
                            {t('navigation:logout')}
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Box>
            <Image
                alt="Logo here"
                src={process.env.PUBLIC_URL + '/img/logo-nav.svg'}
                maxWidth={240}
                sx={{
                    mx:"auto"
                }}
            />
        </Drawer>
    );
};

export default MobileNav;
