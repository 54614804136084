import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import Image from "../../Image";
import theme from "../../../themes/default";
import {Navigate, Outlet} from "react-router-dom";
import {Snackbar} from "../../ui/Snackbar";
import {useTranslation} from 'react-i18next';
import useJwt from "../../../hooks/useJwt";

const AuthPage = () => {
    const {t} = useTranslation();
    const jwt = useJwt();

    if(jwt !== null) {
        if (jwt.exp && Date.now() < jwt.exp * 1000) {
            return <Navigate to="/application/scanorder" />;
        }
    }

    return (
        <Grid container sx={{ height: "100vh" }}>
            <Grid
                container
                flexDirection={"column"}
                justifyContent={"center"}
                flexGrow={1}
                item
                position={"relative"}
                flexWrap={{
                    xs: "nowrap",
                    md: 'wrap'
                }}
                xs={12}
                md={4}
            >
                <Box
                    width={"100%"}
                    position={"absolute"}
                    top={40}
                    left={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Image
                        alt={t('auth:title')}
                        maxWidth={150}
                        src={process.env.PUBLIC_URL + '/img/logo_alternate.svg'}
                    />
                </Box>
                <Grid
                    alignItems={"center"}
                    container
                    flexDirection={"column"}
                    flexWrap={"nowrap"}
                    item
                    rowGap={4}
                    marginY={{
                        xs: "93px",
                        md: "140px"
                    }}
                >
                    <Grid
                        item
                        width={"100%"}
                        xs={11}
                        md={9}
                        lg={8}
                    >
                        <Box id="pageTitle" />
                    </Grid>
                    <Grid
                        item
                        width={"100%"}
                        xs={11}
                        md={9}
                        lg={8}
                    >
                        <Outlet/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                flexGrow={1}
                item
                position={"relative"}
                sx={{
                    flexGrow: 1,
                }}
                hidden
                display={{
                    md: 'flex'
                }}
                xs={12}
                md={8}
            >
                <Box
                    alt={t('auth:tagline')}
                    component="img"
                    display={"block"}
                    height={"100%"}
                    position={"absolute"}
                    src={process.env.REACT_APP_PUBLIC_URL + '/img/bg_auth2.png'}
                    width={"100%"}
                    sx={{
                        objectFit: "cover",
                    }}
                />
                <Box
                    bgcolor={theme.palette.primary.main}
                    display={"flex"}
                    height={"100%"}
                    justifyContent={"center"}
                    position={"absolute"}
                    width={"100%"}
                    sx={{
                        opacity: "20%"
                    }}
                >
                    <Box
                        bottom={100}
                        maxWidth={370}
                        position={"absolute"}
                        textAlign={"center"}
                        width={"100%"}
                    >
                        <Image
                            sx={{
                                paddingBottom: "27px",
                                mx: "auto",
                            }}
                            alt="Prometeus"
                            src={process.env.PUBLIC_URL + '/img/logo.svg'}
                            maxWidth={100}
                        />
                        <Typography
                            color={"#FFF"}
                            fontSize={22}
                            fontWeight={700}
                        >
                            {t('auth:tagline')}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Snackbar />
        </Grid>
    );
}


export default AuthPage;
