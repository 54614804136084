import {PaletteOptions} from "@mui/material";

const palette: PaletteOptions = {
    primary: {
        light: "#D7E3F2",
        main: "#232323",
        dark: "#000000"
    },
    secondary: {
        main: "#dd0b15",
        dark: "#b61228",
        light: "#FF5783"
    },
    error: {
      main: "#EB334C" ,
    },
    heading: {
        main: "#FFFFFF",
    },
    text: {
        primary: "#000000",
    },
   divider: "#D5DCE2",
};

export default palette;