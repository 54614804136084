import {DialogProps,} from "@mui/material";
import React, {FC} from "react";
import {AppDialog} from "./AppDialog";

export type FormDialogProps = DialogProps & {
    close: () => void,
    title: string,
    maxWidth: false
}

export const FormDialog: FC<FormDialogProps> = ({children, close, open, title, fullScreen, maxWidth}) => {
    return (
        <AppDialog
            open={open}
            onClose={close}
            title={title}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
        >
                { children }
        </AppDialog>
    );
}
