import {ListItemText, MenuItem, Theme} from "@mui/material";
import React, {ReactNode} from "react";
import {Link, useLocation} from "react-router-dom";
import useJwt from "../../hooks/useJwt";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SxProps} from "@mui/system";
import useSecurity from "../../hooks/useSecurity";

const menuItemPrimaryTextStyles = {
    fontWeight: {
        xs: 600,
        md: 500,
    },
    fontSize: {
        xs: 19,
        md: 16,
    },
    sx: {
        textTransform: "capitalize"
    },
};

const menuItemStyles = {
    marginRight: "-24px",
    marginLeft: "-25px",
    paddingLeft: "50px",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#000000",
    '&:hover': {
        '&:after': {
            content: "''",
            position: "absolute",
            width: "3px",
            height: "100%",
            backgroundColor: "#E83B3B",
            right: 0,
            top: 0
        }
    },
    '&.Mui-selected': {
        color: "#E83B3B",
        '&:after': {
            content: "''",
            position: "absolute",
            width: "3px",
            height: "100%",
            backgroundColor: "#E83B3B",
            right: 0,
            top: 0
        }
    },
};

const subMenuItemPrimaryTextStyles = {
    borderRadius: 0,
    fontWeight: {
        xs: 700,
        md: 500
    },
    fontSize: {
        xs: 24,
        md: 16,
    }
};

const subMenuItemStyles = {
    borderRadius:0,
    color: "#ffffff",
};

type MainMenuItemProps = {
    onClick?: () => void;
    to: string;
    primary: string;
    role: string;
    hideForRole?: string;
    isSubmenuItem?: boolean;
    hasSubmenu?: boolean;
    disabled?: boolean;
    newTab?: boolean;
    icon?: ReactNode;
    sx?: SxProps<Theme>;
};

const MainMenuItem = ({onClick, to, primary, hideForRole, role, isSubmenuItem, hasSubmenu, disabled, newTab = false, icon, sx = {}}: MainMenuItemProps) => {
    const jwt = useJwt();
    const hasRole = useSecurity();
    const location = useLocation();

    if (hideForRole) {
        if (!jwt) return <></>;
        if (hasRole(hideForRole)) return <></>;
    }

    if (role) {
        if (!jwt) return <></>;
        if (!hasRole(role)) return <></>;
    }

    const itemStyles = isSubmenuItem ? subMenuItemStyles : menuItemStyles;
    const textStyles = isSubmenuItem ? subMenuItemPrimaryTextStyles : menuItemPrimaryTextStyles;

    if (true === newTab) {
        return (
            <MenuItem disabled={disabled} sx={{...itemStyles, ...sx}} component={"a"} href={to} target={"_blank"} rel={"noreferrer"} selected={0 === location.pathname.indexOf(to)}>
                {icon}
                <ListItemText primaryTypographyProps={textStyles} primary={primary} />
                {hasSubmenu && <FontAwesomeIcon icon={faChevronDown} fontSize={"small"} />}
            </MenuItem>
        );
    }

    return (
        <MenuItem disabled={disabled} sx={{...itemStyles, ...sx}} component={Link} to={to} onClick={onClick} selected={0 === location.pathname.indexOf(to)}>
            {icon}
            <ListItemText primaryTypographyProps={textStyles} primary={primary} />
            {hasSubmenu && <FontAwesomeIcon icon={faChevronDown} fontSize={"small"} />}
        </MenuItem>
    );
};

export default MainMenuItem;
