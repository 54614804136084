import MuiTypography from "./default/components/MuiTypography";
import palette from './default/palette'
import typography from "./default/typography";
import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: palette,
    typography: typography,
    components: {
        MuiTypography,
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#b61228',
                },
            },
        },
    },
});

export default theme;
