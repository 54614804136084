import {Box, MenuList} from "@mui/material";
import React from "react";
import {useTranslation} from 'react-i18next';
import MainMenuItem from "./MainMenuItem";
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import Settings from "@mui/icons-material/SettingsOutlined";
import {faBarcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type NavigationProps = {
    onClose?: () => void;
};

const Navigation = ({ onClose }: NavigationProps) => {
    const {t} = useTranslation();

    return (
        <>
            <Box
                flexGrow={1}
                mb={2}
                sx={{
                    width: '100%',
                    maxWidth: '100%'
                }}
            >
                <MenuList>
                    <MainMenuItem to={"/application/scanorder"} primary={t('navigation:scanOrder')} role={'ROLE_USER'} onClick={ onClose } icon={ <DocumentScannerOutlinedIcon sx={{ marginRight: "10px" }} /> }/>
                    <MainMenuItem to={"/application/salesorders"} primary={t('navigation:salesOrders')} role={'ROLE_USER'} onClick={onClose} icon={ <GridViewOutlinedIcon sx={{ marginRight: "10px" }} />} />
                    <MainMenuItem to={"/application/skus"} primary={t('navigation:skus')} role={'ROLE_ADMIN'} onClick={onClose} icon={ <FontAwesomeIcon icon={faBarcode} style={{ marginLeft: "4px", marginRight: "14px" }} /> } />
                    <MainMenuItem to={"/application/labels"} primary={t('navigation:labels')} role={'ROLE_ADMIN'} onClick={onClose} icon={ <LocalOfferOutlinedIcon sx={{ marginRight: "10px" }} /> } />
                    <MainMenuItem to={"/application/users"} primary={t('navigation:users')} role={'ROLE_ADMIN'} onClick={onClose} icon={ <SupervisedUserCircleOutlinedIcon sx={{ marginRight: "10px" }} /> } />
                    <MainMenuItem to={"/application/settings"} primary={t('navigation:settings')} role={'ROLE_SUPER_ADMIN'} onClick={onClose} icon={ <Settings sx={{ marginRight: "10px" }} /> }/>
                </MenuList>
            </Box>
        </>
    );
};

export default Navigation;
