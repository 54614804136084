import React from "react";
import Navigation from "./Navigation";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {Box, Button} from "@mui/material";
import {logout} from "../../redux/slices/auth";
import {useTranslation} from 'react-i18next';
import api from "../../redux/api/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/sharp-regular-svg-icons/faPowerOff";

const DesktopNav = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 64px)" px={3} pt={5}>
            <Navigation />
            <Box mt="auto" pb={3} sx={{justifyContent: "center", marginLeft: "-10px", borderTop: "solid #EFECE7 1px"}} display="flex">
                <Button
                    aria-label={t('navigation:logout')}
                    sx={{
                        fontWeight: {
                            xs: 500,
                            md: 600,
                        },
                        fontSize: {
                            xs: 19,
                            md: 16,
                        },
                        color: "#2C2C2C",
                        textTransform: "capitalize",
                        mt: "15px",
                        mb: "-10px"
                    }}
                    onClick={() => {
                        dispatch(api.util.resetApiState());
                        dispatch(logout());
                    }}
                >
                    <FontAwesomeIcon icon={faPowerOff} style={{marginRight: "10px"}} />
                    {t('navigation:logout')}
                </Button>
            </Box>
        </Box>
    );
};

export default DesktopNav;
