import {Card, CardContent, Divider, Stack, Typography} from "@mui/material";
import React, {FC, PropsWithChildren} from "react";

export type SettingsCardProps = PropsWithChildren & {
    title: string
}

export const SettingsCard: FC<SettingsCardProps> = ({title, children}) => {
    return (
        <Card variant={'outlined'} sx={{marginBottom: 3}}>
            <CardContent sx={{px:3}}>
                <Typography variant={"h2"} component={"h2"} gutterBottom>
                    {title}
                </Typography>
                <Divider sx={{mt: 2, mb: 3, mx: -3}} />
                <Stack direction={"column"} spacing={3}>
                    {children}
                </Stack>
            </CardContent>
        </Card>
    );
}
