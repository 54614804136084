import React, {FC, useRef, useState} from 'react';
import {GridColDef} from "@mui/x-data-grid";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {useTranslation} from "react-i18next";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../datagrid/DynamicDataGrid";
import {Helmet} from "react-helmet-async";
import {Divider, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {DateTimeRenderer} from "../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../themes/default";
import {useGetSkusCollectionQuery} from "../../../redux/api/skus";
import {SkusFilter} from "./skus/SkusFilter";

const Skus: FC = () => {
    const {t} = useTranslation();
    const [currentFilters, setCurrentFilters] = useState<RequestFilter>([]);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const columns: GridColDef[] = [
        { field: 'sku', headerName: t('datagrid:skus:sku'), editable: false, minWidth: 150, renderCell: (params) => params.row.sku },
        { field: 'description', headerName: t('datagrid:skus:description'), editable: false, flex: 1, minWidth: 400, renderCell: (params) => params.row.description },
        { field: 'barcode', headerName: t('datagrid:skus:barcode'), editable: false, minWidth: 175, renderCell: (params) => params.row.barcode },
        { field: 'productId', headerName: t('datagrid:skus:productId'), editable: false, minWidth: 175, renderCell: (params) => params.row.productId },
        { field: 'amountOfLabels', headerName: t('datagrid:skus:totalLabels'), editable: false, minWidth: 100},
        { field: 'createdAt', headerName: t('datagrid:skus:createdAt'), editable: false, minWidth: 200, renderCell: (params) => <DateTimeRenderer date={params.row.createdAt} /> }
    ];

    let filteredColumns: GridColDef<any, any, any>[] = [];

    filteredColumns = columns.map(column => ({ ...column }));

    return (
        <>
            <Helmet>
                <title>{t('helmet:skus')}</title>
            </Helmet>
            <Paper
                sx={{
                    flex: 1,
                    overflow: "hidden",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.primary.light}`
                }}
            >
                <Grid container>
                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                        <Grid item xs={12} sm={6} >
                            <Typography variant="h5" py={0.75}>
                                {t('pageTitle:skus')}
                            </Typography>
                        </Grid>
                    </Stack>

                    <Divider sx={{width: "100%"}} />

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                        }}
                        filter={currentFilters}
                        filterComponent={<SkusFilter onFilterChanged={(filters: any) => setCurrentFilters(filters)} />}
                        ref={gridRef}
                        columns={filteredColumns}
                        queryHook={useGetSkusCollectionQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                    />
                </Grid>
                <Snackbar />
            </Paper>
        </>
    );
};

export default Skus;