 const useBase64EncodedToBlob = () => (base64Encoded: string): Blob => {
    const byteCharacters = atob(base64Encoded);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray]);
 }

 export default useBase64EncodedToBlob;