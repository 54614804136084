import React, {FC, useRef, useState} from 'react';
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Container, Stack} from "@mui/material";
import {DateTimeRenderer} from "../../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../../themes/default";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../../datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {
    useDeleteLabelMutation,
    useLazyGetLabelPDFQuery,
    useGetLabelsBySourceIdCollectionQuery
} from "../../../../redux/api/labels";
import {RowActions} from "../../../datagrid/RowActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/sharp-regular-svg-icons";
import {DataGridNoRowsOverlay} from "../../../datagrid/DataGridNoRowsOverlay";
import useSecurity from "../../../../hooks/useSecurity";
import {ConfirmDialog} from "../../../dialogs/ConfirmDialog";

const LabelDetails: FC = () => {
    const {t} = useTranslation();
    const hasRole = useSecurity();
    const { id } = useParams();
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const [deleteLabel] = useDeleteLabelMutation();
    const [generatePDF] = useLazyGetLabelPDFQuery();

    const [selectedForDeleting, setSelectedForDeleting] = useState<any|null>(null);

    const generateLabelToPDF = async (sku: string, id: number, language: string, dimension: string) => {
        try {
            const pdfData = await generatePDF({
                id: id,
                dimension: dimension
            }).unwrap();

            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([pdfData]),
            );
            const link = document.createElement('a');
            link.target = "_blank";
            link.href = url;
            link.setAttribute(
                'download',
                `${sku}-${language}-${dimension}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteLabel(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
        }
    }

    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    const labelColumns: GridColDef[] = [
        { field: 'skus.sku', headerName: t('datagrid:labels:sku'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => {
                return params.row.skus.map((sku: any) => sku.sku).join(', ');
            }},
        { field: 'language', headerName: t('datagrid:labels:language'), editable: false, flex: 1, minWidth: 400 },
        { field: 'createdAt', headerName: t('datagrid:labels:createdAt'), editable: false, flex: 1, minWidth: 200, renderCell: (params) => <DateTimeRenderer date={params.row.createdAt} /> },
        { field: 'pdf',
            headerName: t('datagrid:labels:pdf'),
            headerAlign: "left",
            width: 150,
            align: "left",
            renderCell: (params) => {
                const actions: any[] = [];

                params.row.availableDimensions.map((dimension: string) => {
                    actions.push({
                        label: `${t('datagrid:download')}-${dimension}`,
                        text: dimension.toUpperCase(),
                        onClick: () => {
                            generateLabelToPDF(
                                params.row.skus.length > 1 ? params.row.skus.map((sku: any) => sku.sku).join('-') : params.row.skus[0].sku,
                                params.row.id,
                                params.row.language,
                                dimension
                            );
                        }
                    });
                });

                return <RowActions actions={actions}/>;
            },
        },
        {
            field: 'actions',
            headerName: t('datagrid:actions'),
            headerAlign: "left",
            width: 125,
            align: "left",
            renderCell: (params) => {
                const actions = [];

                actions.push({
                    icon: <FontAwesomeIcon height={16} width={16} icon={faTrashCan} style={{color: theme.palette.secondary.main, pointerEvents: 'none'}} />,
                    label: t('datagrid:delete'),
                    onClick: async () => {
                        setSelectedForDeleting(params.row);
                    }
                });

                return <RowActions actions={actions}/>;
            },
        },
    ];

    let filteredColumns: GridColDef<any, any, any>[] = [];
    const memberColumns = ['language', 'skus.sku', 'dimension', 'createdAt', 'pdf'];
    const adminColumns = [...memberColumns, 'actions'];

    if (hasRole('ROLE_ADMIN')) {
        filteredColumns = labelColumns
            .filter(column => adminColumns.includes(column.field))
            .map(column => ({ ...column }));
    } else if (hasRole('ROLE_MEMBER')) {
        filteredColumns = labelColumns
            .filter(column => memberColumns.includes(column.field))
            .map(column => ({...column}));
    }

    return (
        <>
            <Helmet>
                <title>{`${t('helmet:labelDetails')}`}</title>
            </Helmet>
            <Container sx={{width:"100%"}} maxWidth="xl" disableGutters>
                <Stack direction={"column"} spacing={3} sx={{ boxShadow: "none" }}>
                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'language', sort: 'asc' }],
                            },
                        }}
                        slots={{
                            noRowsOverlay: DataGridNoRowsOverlay,
                            toolbar: () => null,
                        }}
                        ref={gridRef}
                        filter={ [{ field: 'source.id', value: id as string }] }
                        columns={filteredColumns}
                        queryHook={useGetLabelsBySourceIdCollectionQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'default',
                            },
                            boxShadow: "none",
                            border: `1px solid ${theme.palette.primary.light}`
                        }}
                    />
                </Stack>
            </Container>

            {selectedForDeleting && (
                <ConfirmDialog
                    cancelLabel={t('button:cancel')}
                    confirmLabel={t('button:delete')}
                    title={t('dialog:deleteLabel')}
                    message={t('dialog:confirmDeleteLabel', { name: selectedForDeleting.language })}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}
        </>
    );
};

export default LabelDetails;