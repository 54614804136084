import React from 'react';

import {BrowserRouter, Routes, Route} from "react-router-dom";
import {SecurePage} from "../routing/SecurePage";
import Default from "./private/Default";
import AuthPage from "./public/AuthPage";
import LoginForm from "./public/authpage/LoginForm";
import ForgotPasswordForm from "./public/authpage/ForgotPasswordForm";
import ResetPasswordForm from "./public/authpage/ResetPasswordForm";
import NoPage from "./public/NoPage";
import Layout from "./private/Layout";
import Settings from "./private/Settings";
import ScrollToTop from "../ScrollToTop";
import SalesOrders from "./private/SalesOrders";
import SalesOrderDetails from "./private/salesorders/SalesOrderDetails";
import Skus from "./private/Skus";
import LabelDetails from "./private/labels/LabelDetails";
import Labels from "./private/Labels";
import EditLabel from "./private/labels/EditLabel";
import Users from "./private/Users";

function Router() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<AuthPage />}>
                    <Route index element={<LoginForm />} />
                    <Route path="forgotpassword" element={<ForgotPasswordForm />} />
                </Route>
                <Route path="application" element={<SecurePage role="ROLE_USER"><Layout /></SecurePage>}>
                    <Route index path="scanorder" element={<SecurePage role="ROLE_USER"><Default /></SecurePage>} />
                    <Route path="salesorders" element={<SecurePage role="ROLE_USER"><SalesOrders /></SecurePage>} />
                    <Route path="salesorders/details/:id" element={<SecurePage role="ROLE_USER"><SalesOrderDetails /></SecurePage>} />
                    <Route path="skus" element={<SecurePage role="ROLE_ADMIN"><Skus /></SecurePage>} />
                    <Route path="labels" element={<SecurePage role="ROLE_ADMIN"><Labels /></SecurePage>} />
                    <Route path="labels/details/:id" element={<SecurePage role="ROLE_ADMIN"><LabelDetails /></SecurePage>} />
                    <Route path="labels/edit/:id" element={<SecurePage role="ROLE_ADMIN"><EditLabel /></SecurePage>} />
                    <Route path="users" element={<SecurePage role="ROLE_ADMIN"><Users /></SecurePage>} />
                    <Route path="settings" element={<SecurePage role="ROLE_SUPER_ADMIN"><Settings /></SecurePage>} />
                </Route>

                <Route path="*" element={<NoPage />} />
                <Route path="login" element={<AuthPage />}>
                    <Route index element={<LoginForm />} />
                    <Route path="forgotpassword" element={<ForgotPasswordForm />} />
                </Route>
                <Route path="reset-password" element={<AuthPage />}>
                    <Route index element={<ResetPasswordForm />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
