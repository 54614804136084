import React, {FC} from "react";
import {Box} from "@mui/system";

type HtmlRendererProps = {
    html: string,
};

export const HtmlRenderer: FC<HtmlRendererProps> = ({html}) => {
    return (
        <Box
            bgcolor="#e1f5fe"
            borderRadius="5px"
            padding="15px"
            fontWeight={400}
            fontSize={14}
            color="#014361"
            sx={{
                whiteSpace: "pre-line",
                lineHeight: 1.6
            }}
        >
            {html}
        </Box>
    );
}