import {IconButton, Typography} from "@mui/material";
import React, {FC, ReactNode} from "react";

export type RowAction = {
    icon?: ReactNode,
    label: string,
    text?: string,
    disabled?: boolean,
    onClick: () => void,
};

type RowActionsProps = {
    actions: Array<RowAction>,
};

export const RowActions: FC<RowActionsProps> = ({actions}) => {
    return (
        <>
            {actions.map(action =>
                <div  key={action.label} onClick={() => action.onClick()}>
                    {action.icon &&
                        <IconButton disabled={action.disabled} sx={{ padding: '6px', fontSize: '16px', marginRight: '3px' }}>
                            {action.icon}
                        </IconButton>
                    }

                    {action.text &&
                        <Typography
                            component="span"
                            sx={{
                                marginRight: "10px",
                                cursor: "pointer",
                                ":hover": {
                                    textDecoration: "underline"
                                }
                            }}
                            fontWeight={600}
                            fontSize={13}
                        >
                            {action.text}
                        </Typography>
                    }
                </div>
            )}
        </>
    );
}
