// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {FC} from 'react';
import {FormikProps, FormikValues, useFormikContext} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {Box, GlobalStyles} from "@mui/material";

export type WysiwygFieldProps = {
    formik?: FormikProps<FormikValues>,
    name: string,
}

const inputGlobalStyles = (
    <GlobalStyles
        styles={{
            ".ck.ck-balloon-panel": {
                zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generally 1300)
            },
        }}
    />
);

export const WysiwygField: FC<WysiwygFieldProps> = ({formik, name}) => {
    const formikContext = useFormikContext();
    const formikToUse = formik || formikContext;
    const value = ArrayHelper.get(formikToUse.values, name);

    return (
        <Box>
            {inputGlobalStyles}
            <CKEditor
                editor={Editor}
                data={value || ''}
                onChange={(event, editor) => formikToUse.setFieldValue(name, editor.getData())}
            />
        </Box>
    )
}
