import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en_US from "./locales/en_US.json";

export const defaultNS = "translation";
export const resources = {
    en: en_US,
} as const;

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: "en",
        ns: [defaultNS],
        defaultNS,
        resources
    });

export default i18next;